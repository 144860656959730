.te-styled {
  /* HTML5 display-role reset for older browsers */
}
.te-styled html,
.te-styled body,
.te-styled div,
.te-styled span,
.te-styled .span,
.te-styled h1,
.te-styled h2,
.te-styled h3,
.te-styled h4,
.te-styled h5,
.te-styled h6,
.te-styled p,
.te-styled blockquote,
.te-styled pre,
.te-styled a,
.te-styled address,
.te-styled .address,
.te-styled code,
.te-styled del,
.te-styled img,
.te-styled tt,
.te-styled center,
.te-styled .center,
.te-styled dl,
.te-styled dt,
.te-styled dd,
.te-styled ol,
.te-styled ul,
.te-styled li,
.te-styled form,
.te-styled .form,
.te-styled label,
.te-styled .label,
.te-styled table,
.te-styled .table,
.te-styled tbody,
.te-styled tfoot,
.te-styled thead,
.te-styled tr,
.te-styled th,
.te-styled td,
.te-styled article,
.te-styled .article,
.te-styled aside,
.te-styled .aside,
.te-styled figure,
.te-styled .figure,
.te-styled figcaption,
.te-styled footer,
.te-styled .footer,
.te-styled header,
.te-styled .header,
.te-styled nav,
.te-styled .nav,
.te-styled section,
.te-styled .section,
.te-styled audio,
.te-styled video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  vertical-align: baseline;
}
.te-styled article,
.te-styled aside,
.te-styled footer,
.te-styled header,
.te-styled hgroup,
.te-styled menu,
.te-styled nav,
.te-styled section {
  display: block;
}
.te-styled body {
  line-height: 1;
  color: #333;
}
.te-styled ol,
.te-styled ul {
  list-style: none;
}
@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.tipser.com/fonts/Gotham/Gotham-Book.woff2') format('woff2'), url('https://cdn.tipser.com/fonts/Gotham/Gotham-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.tipser.com/fonts/Gotham/Gotham-Bold.woff2') format('woff2'), url('https://cdn.tipser.com/fonts/Gotham/Gotham-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.tipser.com/fonts/Gotham/Gotham-Medium.woff2') format('woff2'), url('https://cdn.tipser.com/fonts/Gotham/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.tipser.com/fonts/Gotham/Gotham-Book-Italic.woff2') format('woff2'), url('https://cdn.tipser.com/fonts/Gotham/Gotham-Book-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.tipser.com/fonts/Gotham/Gotham-Light.woff2') format('woff2'), url('https://cdn.tipser.com/fonts/Gotham/Gotham-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
.te-dialog.te-styled {
  font-size: 16px;
  font-family: 'Gotham', 'Arial', sans-serif;
}
.te-dialog.te-styled .te-dialog-card {
  margin: 0 auto;
}
.te-dialog.te-styled .te-dialog-header-fixed {
  width: 94%;
  border-bottom: none;
  padding: 10px 0 10px 0;
}
.te-dialog.te-styled .te-dialog-header-fixed.on-modal-scroll {
  top: 10px;
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
}
.te-dialog.te-styled .te-dialog-header-fixed-logo {
  margin-right: auto;
}
.te-dialog.te-styled .te-dialog-header-fixed-logo-wrapper {
  margin-left: 20px;
}
.te-dialog.te-styled .te-dialog-inner {
  display: block;
  padding-top: 50px;
}
.te-dialog.te-styled .te-footer {
  border-top: 1px solid #dcdee0;
}
.te-dialog.te-styled .te-footer-contact {
  padding: 40px 25px 30px;
  flex-direction: column;
}
.te-dialog.te-styled .te-footer-contact.medium,
.te-dialog.te-styled .te-footer-contact.large {
  flex-direction: row;
}
.te-dialog.te-styled .te-footer-contact-list {
  margin: 10px 0;
}
.te-dialog.te-styled .te-footer-contact-column:first-child {
  margin-bottom: 20px;
}
.te-dialog.te-styled .te-footer-row {
  margin: 0 0 40px;
}
.te-dialog.te-styled .te-footer-row.large {
  margin: 0 150px 40px;
}
.te-dialog.te-styled .te-footer-terms {
  padding: 0 25px;
}
.te-dialog.te-styled .te-footer-terms-container {
  display: flex;
  flex-direction: column;
  padding: 0 0 40px;
}
.te-dialog.te-styled .te-footer-terms-single {
  margin-bottom: 10px;
}
.te-dialog.te-styled .te-footer-tipser-logo-icon {
  margin-left: 7px;
}
.te-dialog.te-styled .te-cart-size-indicator {
  font-size: 0.7em;
}
.te-dialog.te-styled .te-cart-list {
  padding: 0px 10px;
}
.te-dialog.te-styled .te-cart-list-wrapper {
  padding: 15px;
  border: 1px solid #e7e7e7;
}
.te-dialog.te-styled .te-cart-list-header {
  margin: 10px 10px 20px;
}
.te-dialog.te-styled .te-cart-list-header-line {
  border-bottom: 2px solid #4f5058;
}
.te-dialog.te-styled .te-cart-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
}
.te-dialog.te-styled .te-cart-list-item-info {
  margin-left: 18px;
  padding: 0 10px 4px 0;
}
.te-dialog.te-styled .te-cart-list-item-info-price-sales {
  margin-left: 10px;
}
.te-dialog.te-styled .te-cart-list-item-info-attribute {
  margin-bottom: 8px;
}
.te-dialog.te-styled .te-cart-list-item-info-total-qty-value {
  margin-left: 12px;
}
.te-dialog.te-styled .te-separate-line {
  border-bottom: 1px solid #e7e7e7;
}
.te-dialog.te-styled .te-cart-summary {
  margin-top: 20px;
  padding: 0 10px;
}
.te-dialog.te-styled .te-cart-summary-order {
  margin-bottom: 10px;
}
.te-dialog.te-styled .te-cart-summary-delivery {
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
}
.te-dialog.te-styled .te-cart-summary-total {
  padding-top: 15px;
}
.te-dialog.te-styled .te-cart-checkout-button {
  margin-top: 20px;
}
.te-dialog.te-styled .te-button-text {
  padding: 0 40px;
}
