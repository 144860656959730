.te-store {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}
.te-store .te-store-collection {
  width: 100%;
}
.te-store .te-store-collection .te-collection {
  margin: 0;
}
.te-store .te-store-mobile-menu {
  background-color: #ebebeb;
  border-radius: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: relative;
  width: auto;
}
.te-store .te-store-mobile-menu select {
  border: 0;
  outline: 0;
  padding: 5px 10px;
  text-transform: uppercase;
  text-indent: 1px;
  text-overflow: '';
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.te-store .te-store-mobile-menu svg {
  width: 1.8em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25px;
  pointer-events: none;
}
.te-store .te-store-desktop-menu {
  box-sizing: border-box;
  display: block;
  width: 100%;
  overflow-x: auto;
  position: relative;
  padding: 0 20px;
  white-space: nowrap;
  height: auto;
  text-align: center;
  margin-top: 20px;
  min-width: 200px;
}
.te-store .te-store-desktop-menu::-webkit-scrollbar {
  display: none;
}
.te-store .te-store-menu-item {
  display: inline-block;
  padding: 6px 7px;
  margin: 0 5px;
  color: #333;
  background-color: #f5f5f5;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s ease;
}
.te-store .te-store-menu-item-active {
  background-color: #ebebeb;
}
.te-store-small .te-store-desktop-menu {
  display: flex;
  flex-wrap: wrap;
}
.te-store-small .te-store-desktop-menu .te-store-menu-item {
  margin: 5px;
}
.te-store-small .te-store-desktop-menu .te-store-menu-item.te-active {
  border-width: 1px;
  border-style: solid;
}
@media (min-width: 768px) {
  .te-store-small .te-store-menu {
    display: flex;
    flex-wrap: wrap;
  }
  .te-store-small .te-store-menu .te-store-menu-item {
    margin: 5px;
  }
}
.te-store-large {
  flex-direction: row;
}
.te-store-large .te-store-desktop-menu {
  width: auto;
  white-space: normal;
}
.te-store-large .te-store-menu-item {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.7);
  display: block;
  font-weight: 300;
  letter-spacing: 0.5px;
  list-style: none;
  margin: 0;
  padding: 8px 7px;
  text-align: left;
  transition: all 0.5s ease-in-out;
}
.te-store-menu-item {
  border-left: 2px solid transparent;
}
.te-store-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.te-store-menu-item-active,
.te-store-menu-item:hover {
  border-left-style: solid;
  border-left-width: 2px;
}
