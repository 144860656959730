.te-styled {
  /* HTML5 display-role reset for older browsers */
}
.te-styled html,
.te-styled body,
.te-styled div,
.te-styled span,
.te-styled .span,
.te-styled h1,
.te-styled h2,
.te-styled h3,
.te-styled h4,
.te-styled h5,
.te-styled h6,
.te-styled p,
.te-styled blockquote,
.te-styled pre,
.te-styled a,
.te-styled address,
.te-styled .address,
.te-styled code,
.te-styled del,
.te-styled img,
.te-styled tt,
.te-styled center,
.te-styled .center,
.te-styled dl,
.te-styled dt,
.te-styled dd,
.te-styled ol,
.te-styled ul,
.te-styled li,
.te-styled form,
.te-styled .form,
.te-styled label,
.te-styled .label,
.te-styled table,
.te-styled .table,
.te-styled tbody,
.te-styled tfoot,
.te-styled thead,
.te-styled tr,
.te-styled th,
.te-styled td,
.te-styled article,
.te-styled .article,
.te-styled aside,
.te-styled .aside,
.te-styled figure,
.te-styled .figure,
.te-styled figcaption,
.te-styled footer,
.te-styled .footer,
.te-styled header,
.te-styled .header,
.te-styled nav,
.te-styled .nav,
.te-styled section,
.te-styled .section,
.te-styled audio,
.te-styled video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  vertical-align: baseline;
}
.te-styled article,
.te-styled aside,
.te-styled footer,
.te-styled header,
.te-styled hgroup,
.te-styled menu,
.te-styled nav,
.te-styled section {
  display: block;
}
.te-styled body {
  line-height: 1;
  color: #333;
}
.te-styled ol,
.te-styled ul {
  list-style: none;
}
div.te-styled .te-checkout {
  font-size: 16px;
  font-family: 'Gotham', 'Arial', sans-serif;
}
