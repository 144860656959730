.te-footer,
.te-styled .te-footer {
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #dcdee0;
  background-color: #f6f6f6;
  display: block;
}
.te-footer-container,
.te-styled .te-footer-container {
  display: inline-flex;
  flex-direction: column;
}
.te-footer-container.large,
.te-styled .te-footer-container.large,
.te-footer-container.medium,
.te-styled .te-footer-container.medium {
  display: flex;
}
.te-footer-contact,
.te-styled .te-footer-contact {
  padding: 40px 25px 30px;
  display: inline-flex;
  color: #4f5058;
  flex-direction: column;
  align-items: flex-start;
}
.te-footer-contact.large,
.te-styled .te-footer-contact.large,
.te-footer-contact.medium,
.te-styled .te-footer-contact.medium {
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
}
.te-footer-contact.large .te-footer-contact-column,
.te-styled .te-footer-contact.large .te-footer-contact-column,
.te-footer-contact.medium .te-footer-contact-column,
.te-styled .te-footer-contact.medium .te-footer-contact-column {
  margin: 10px 0 0 150px;
}
.te-footer-contact-title,
.te-styled .te-footer-contact-title {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}
.te-footer-contact-column,
.te-styled .te-footer-contact-column {
  text-align: left;
}
.te-footer-contact-column:first-child,
.te-styled .te-footer-contact-column:first-child {
  margin-bottom: 20px;
}
.te-footer-contact-email,
.te-styled .te-footer-contact-email {
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}
.te-footer-contact-list,
.te-styled .te-footer-contact-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 1.6em;
  font-size: 11px;
  list-style-type: none;
}
.te-footer-contact-list.large,
.te-styled .te-footer-contact-list.large,
.te-footer-contact-list.medium,
.te-styled .te-footer-contact-list.medium {
  font-size: 12px;
}
.te-footer-contact-list-item,
.te-styled .te-footer-contact-list-item {
  display: flex;
  align-items: center;
  line-height: 2.5em;
}
.te-footer-contact-list-item svg,
.te-styled .te-footer-contact-list-item svg {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}
.te-footer-contact-list-item a:first-letter,
.te-styled .te-footer-contact-list-item a:first-letter {
  text-transform: capitalize;
}
.te-footer-payment,
.te-styled .te-footer-payment {
  display: flex;
  flex-direction: column;
  text-align: left;
}
@media (min-width: 768px) {
  .te-footer-payment,
  .te-styled .te-footer-payment {
    margin: 20px auto;
    text-align: center;
  }
}
.te-footer-payment-title,
.te-styled .te-footer-payment-title {
  font-weight: 500;
  margin: 30px auto 10px auto;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.te-footer-payment-logos,
.te-styled .te-footer-payment-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.te-footer-payment-logos :not(:first-child):not(:last-child),
.te-styled .te-footer-payment-logos :not(:first-child):not(:last-child) {
  margin: 0 10px;
}
.te-footer-payment-logo,
.te-styled .te-footer-payment-logo {
  border-radius: 5px;
  width: 45px;
  height: 35px;
  padding: 10px 0;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.te-footer-payment-logo > svg,
.te-styled .te-footer-payment-logo > svg {
  width: 100%;
  height: 100%;
}
.te-footer-tipser-logo,
.te-styled .te-footer-tipser-logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  color: #4f5058;
}
.te-footer-tipser-logo-icon,
.te-styled .te-footer-tipser-logo-icon {
  margin-left: 7px;
}
.te-footer-tipser-logo-icon svg,
.te-styled .te-footer-tipser-logo-icon svg {
  width: 100px;
}
.te-footer-terms,
.te-styled .te-footer-terms {
  padding: 0 25px;
  width: fit-content;
}
.te-footer-terms-container,
.te-styled .te-footer-terms-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 40px;
  font-size: 12px;
}
.te-footer-terms-container.large,
.te-styled .te-footer-terms-container.large,
.te-footer-terms-container.medium,
.te-styled .te-footer-terms-container.medium {
  flex-direction: row;
  padding: 0;
}
.te-footer-terms-single,
.te-styled .te-footer-terms-single {
  margin-bottom: 10px;
}
.te-footer-terms-single a,
.te-styled .te-footer-terms-single a {
  color: #a3a0a0;
  font-size: 11px;
  text-decoration: none;
}
.te-footer-terms-single a:hover,
.te-styled .te-footer-terms-single a:hover {
  color: #262626;
}
.te-footer-terms-single.large,
.te-styled .te-footer-terms-single.large,
.te-footer-terms-single.medium,
.te-styled .te-footer-terms-single.medium {
  margin: 0 15px 10px 0;
  position: relative;
}
.te-footer-row,
.te-styled .te-footer-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
}
.te-footer-row.large,
.te-styled .te-footer-row.large {
  flex-direction: row;
  justify-content: space-between;
  margin: 0 150px 40px;
}
.te-footer .footer-link a,
.te-styled .te-footer .footer-link a {
  color: #262626;
  text-decoration: none;
}
.te-footer .footer-link a:hover,
.te-styled .te-footer .footer-link a:hover {
  color: #a3a0a0;
}
.te-footer-contact .te-footer-contact-list-link a,
.te-styled .te-footer-contact .te-footer-contact-list-link a,
.te-footer-terms .te-footer-contact-list-link a,
.te-styled .te-footer-terms .te-footer-contact-list-link a {
  color: #262626;
  text-decoration: none;
}
.te-footer-contact .te-footer-contact-list-link a:hover,
.te-styled .te-footer-contact .te-footer-contact-list-link a:hover,
.te-footer-terms .te-footer-contact-list-link a:hover,
.te-styled .te-footer-terms .te-footer-contact-list-link a:hover {
  color: #a3a0a0;
}
.te-footer-contact .te-footer-contact-list-item a,
.te-styled .te-footer-contact .te-footer-contact-list-item a,
.te-footer-terms .te-footer-contact-list-item a,
.te-styled .te-footer-terms .te-footer-contact-list-item a {
  color: #262626;
  text-decoration: none;
}
.te-footer-contact .te-footer-contact-list-item a:hover,
.te-styled .te-footer-contact .te-footer-contact-list-item a:hover,
.te-footer-terms .te-footer-contact-list-item a:hover,
.te-styled .te-footer-terms .te-footer-contact-list-item a:hover {
  color: #a3a0a0;
}
