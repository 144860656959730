.te-modular-cart-summary,
.te-styled .te-modular-cart-summary,
.te-modular-cart-summary-total,
.te-styled .te-modular-cart-summary-total {
  color: #303248;
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
  line-height: 1.7;
}
.te-modular-cart-summary .te-label,
.te-styled .te-modular-cart-summary .te-label,
.te-modular-cart-summary-total .te-label,
.te-styled .te-modular-cart-summary-total .te-label {
  text-transform: uppercase;
}
.te-modular-cart-summary .sale,
.te-styled .te-modular-cart-summary .sale,
.te-modular-cart-summary-total .sale,
.te-styled .te-modular-cart-summary-total .sale {
  color: #ec877b;
}
.te-modular-cart-summary.discount,
.te-styled .te-modular-cart-summary.discount,
.te-modular-cart-summary-total.discount,
.te-styled .te-modular-cart-summary-total.discount {
  color: #ec877b;
}
.te-modular-cart-summary.bolder,
.te-styled .te-modular-cart-summary.bolder {
  font-weight: 500;
}
.te-modular-cart-summary-name,
.te-styled .te-modular-cart-summary-name {
  overflow-x: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.te-modular-cart-summary-total span,
.te-styled .te-modular-cart-summary-total span {
  font-weight: 500;
}
.te-modular-cart-summary-total .te-label-not-bold,
.te-styled .te-modular-cart-summary-total .te-label-not-bold {
  font-weight: normal;
}
.te-modular-cart-summary-details,
.te-styled .te-modular-cart-summary-details {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
  padding: 5px 0 10px;
}
.te-modular-cart-summary:last-of-type,
.te-styled .te-modular-cart-summary:last-of-type {
  padding-bottom: 5px;
}
.te-modular-cart-summary-note,
.te-styled .te-modular-cart-summary-note {
  font-size: 0.8em;
  color: #a3a0a0;
}
.te-modular-cart-summary-customs-warning,
.te-styled .te-modular-cart-summary-customs-warning {
  margin-top: 15px;
  color: #a3a0a0;
  line-height: 1.7em;
}
.te-modular-cart-summary-customs-warning a,
.te-styled .te-modular-cart-summary-customs-warning a {
  color: #a3a0a0;
}
.te-modular-cart-summary-promo-code,
.te-styled .te-modular-cart-summary-promo-code {
  color: #ec877b;
}
