@media (max-width: 479px) {
  .te-related-pages {
    padding: 15px;
  }
}
@media (min-width: 480px) and (max-width: 959px) {
  .te-related-pages {
    margin-top: 20px;
    padding: 20px;
  }
}
@media (min-width: 960px) {
  .te-related-pages {
    margin-top: 20px;
    padding: 30px;
  }
}
.te-related-pages .te-related-pages-header {
  margin: 15px auto;
  max-width: 940px;
  font-size: 23px;
  font-weight: 900;
}
@media (max-width: 479px) {
  .te-related-pages .te-related-pages-header {
    margin: 10px 0 20px;
  }
}
.te-related-pages .te-related-pages-box-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 940px;
  margin: auto;
}
.te-related-page-box {
  cursor: pointer;
  margin: 0 10px 10px 0;
  grid-row-gap: 2em;
}
@media (max-width: 767px) {
  .te-related-page-box {
    width: calc(100%/2 - 8px);
  }
  .te-related-page-box:nth-child(2n + 2) {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .te-related-page-box {
    width: calc(100%/4 - 8px);
  }
  .te-related-page-box:nth-child(4n + 4) {
    margin-right: 0;
  }
}
.te-related-page-title {
  font-size: 16px;
  margin: 10px 0 5px;
  line-height: 1.3;
}
.te-related-page-description {
  margin-bottom: 30px;
  font-size: 12px;
  color: #777;
}
.te-related-page-img-wrapper {
  width: 100%;
  max-height: 350px;
  background: gainsboro;
}
@media (max-width: 767px) {
  .te-related-page-img-wrapper {
    height: 50vw;
    max-height: 350px;
  }
}
@media (min-width: 768px) {
  .te-related-page-img-wrapper {
    height: 25vw;
    max-height: 250px;
  }
}
.te-related-page-img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
.te-related-page-box:hover .te-related-page-title {
  opacity: 0.7;
}
.te-related-page-box:hover .te-related-page-description {
  opacity: 0.7;
  text-decoration: underline;
}
