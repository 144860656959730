.te-buy_button {
  cursor: pointer;
  margin: 0;
  width: 100%;
  font-size: 0.8em;
}
.te-buy_button .disabled {
  cursor: not-allowed;
}
.te-buy_button .disabled .te-button-body {
  background-color: #dddddd;
}
.te-buy_button .disabled .te-button-body .te-button-text {
  color: #525860;
}
