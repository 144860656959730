.te-checkout-order-processing,
.te-styled .te-checkout-order-processing {
  text-align: center;
  color: #4f5058;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.te-checkout-order-processing-animation,
.te-styled .te-checkout-order-processing-animation {
  width: 100px;
  height: 250px;
  margin: 60px 0 50px 0;
}
.te-checkout-order-processing-heading,
.te-styled .te-checkout-order-processing-heading {
  font-size: 28px;
  font-weight: 700;
}
.te-checkout-order-processing-text,
.te-styled .te-checkout-order-processing-text {
  margin: 30px 0 170px 0;
}
.te-dialog-inner .te-checkout {
  height: 100%;
}
