.te-collection-container:hover .slick-next,
.te-collection-container:hover .slick-prev {
  height: 90%;
}
.te-collection-container:hover .slick-next:before,
.te-collection-container:hover .slick-prev:before {
  content: '';
  position: absolute;
  top: 0;
  width: 140%;
  height: 100%;
  z-index: -1;
  background-color: black;
  transition: background-color 1s ease-in-out;
}
@media (min-width: 768px) {
  .te-collection-container:hover .slick-next:before,
  .te-collection-container:hover .slick-prev:before {
    opacity: 0.1;
  }
}
.te-collection-container:hover .slick-prev:before {
  transform: translateX(-2px);
  left: 0;
}
.te-collection-container:hover .slick-next:before {
  transform: translateX(2px);
  right: 0;
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-slider {
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-next,
.slick-prev {
  z-index: 1;
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: #000;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0.4;
}
.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 0;
}
.slick-next:before,
.slick-prev:before {
  content: '';
  background-color: transparent;
  opacity: 0;
}
.slick-next {
  top: 50%;
  left: calc(100% - 5px);
  -webkit-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.slick-prev {
  left: 2px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
