.te-details {
  display: flex;
  flex-direction: column;
}
.te-details-name {
  font-size: 1em;
  line-height: 1.4em;
  margin: 0;
  padding: 5px 0;
}
.te-details-brand {
  font-size: 1em;
  letter-spacing: 2px;
  margin: 0;
  padding: 5px 0;
}
