.te-loader-pulse {
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #627b92;
  border-radius: 50%;
  position: relative;
  animation: te-loader-pulse-animation 1s ease-out infinite;
  z-index: 100;
  flex-shrink: 0;
}
.te-loader-pulse-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: 0.3s;
}
.te-loader-pulse-text {
  font-size: 10px;
  text-transform: uppercase;
  margin: 0 7px;
  font-weight: 300;
  line-height: 18px;
}
@keyframes te-loader-pulse-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
