.te-product-page {
  max-width: 1350px;
  margin: 0 auto;
}
.te-product-page .te-color-relations {
  margin: 20px 0;
}
.te-product-page .te-description {
  margin: 20px 20px 40px 20px;
}
