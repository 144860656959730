.te-img.object-fit {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.te-img.object-fit > img {
  visibility: hidden;
  display: inherit;
  height: inherit;
  width: inherit;
  max-height: inherit;
  min-height: inherit;
  max-width: 100%;
  min-width: inherit;
}
.te-img.object-fit.object-fit-cover {
  background-size: cover;
}
.te-img.object-fit.object-fit-contain {
  background-size: contain;
}
.te-img.object-fit .img-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  opacity: 1;
  filter: blur(8px);
}
.te-img.object-fit .img-placeholder-loaded {
  opacity: 0;
}
.te-img.object-fit.object-fit-invalid {
  background-color: #dddddd;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
