.te-page {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.te-page a.menu-close {
  position: absolute;
  right: 30px;
  top: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.4s;
}
.te-page a.menu-close:hover {
  color: white;
}
.te-page #page-main-shopping-cart {
  cursor: pointer;
  position: relative;
}
.te-page #page-main-shopping-cart.active {
  animation-name: heartbeat;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
    color: #333;
  }
  20% {
    transform: scale(1.1);
    color: #437a94;
  }
  35% {
    transform: scale(0.9);
    color: #7aacc3;
  }
  50% {
    transform: scale(1.2);
    color: #437a94;
  }
  99% {
    transform: scale(1);
    color: #437a94;
  }
  100% {
    color: #333;
  }
}
.te-page .tipser-icon-cart {
  font-size: 1.3em;
}
.te-page .tipser-cart-size-indicator {
  position: absolute;
  right: 3px;
  font-size: 8px;
  color: white;
  bottom: -2px;
  border: 1px solid;
  border-radius: 50px;
  line-height: 1.8em;
  background-color: #437a94;
  width: 2em;
  height: 2em;
  text-align: center;
}
.te-page #page-main-usp-container {
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 22px;
  padding: 0 5px;
  z-index: 999;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.te-page #page-main-usp-container .main-usp {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  display: inline-flex;
}
.te-page #page-main-usp-container .main-usp .usp-item {
  color: white;
  display: inline-flex;
  padding: 0 5px 0 0;
}
.te-page #page-main-usp-container .main-usp .usp-item .tipser-icon {
  font-size: 0.7em;
}
.te-page #page-main-usp-container .main-usp .usp-item .usp-text {
  font-size: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-left: 5px;
}
.te-page .main-menu-inner-container {
  padding-top: 50px;
  font-size: 14px;
  line-height: 2.5;
}
@media (min-width: 768px) {
  .te-page .main-menu-inner-container {
    font-size: 14px;
    line-height: 3;
  }
}
.te-page #te-page-content-container {
  flex: 0 0 auto;
}
@media (max-width: 768px) {
  .te-page #te-page-content-container {
    flex: 0 0 45px;
  }
}
@media (max-width: 768px) {
  .te-page {
    flex-direction: column;
  }
}
