.te-article-rich .rich-article-image {
  width: 100%;
}
.te-article-rich .te-article-content-container {
  display: block;
}
.te-article-rich .te-article-content-container.justified-left {
  text-align: left;
}
.te-article-rich .te-article-content-container.justified-right {
  text-align: right;
}
.te-article-rich .te-article-content-container.justified-center {
  text-align: center;
}
