.te-description,
.te-styled .te-product-page .te-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
}
.te-description-title,
.te-styled .te-product-page .te-description-title {
  color: #4f5058;
  font-weight: 400;
  font-size: 16px;
  margin: 30px 0 10px 0;
  padding: 0;
  text-transform: capitalize;
}
.te-description-content,
.te-styled .te-product-page .te-description-content {
  padding: 10px 23px 30px 23px;
  word-break: break-word;
  margin: 0 auto;
  font-size: 14px;
  max-width: 900px;
  white-space: pre-wrap;
}
.te-description-content p,
.te-styled .te-product-page .te-description-content p {
  margin: 10px 0;
  padding: 0;
}
.te-description-content.te-desktop,
.te-styled .te-product-page .te-description-content.te-desktop {
  padding: 10px 23px 50px 23px;
}
