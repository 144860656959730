.te-modular-thumbnails {
  align-items: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: white;
  box-sizing: border-box;
  display: -webkit-box;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
}
.te-modular-thumbnails figure {
  margin: 0;
  padding: 0;
}
.te-modular-thumbnails .te-thumbnails {
  display: block;
  position: relative;
  overflow: visible;
  max-height: 100%;
  width: 100px;
  margin: 0;
}
.te-modular-thumbnails .te-slider {
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.te-modular-thumbnails .te-slider .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.te-modular-thumbnails .te-slider .slick-list .slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.te-modular-thumbnails .te-slider .slick-slide {
  display: block;
  text-align: center;
  box-sizing: border-box;
  height: 120px;
}
.te-modular-thumbnails .te-slider .slick-slide .te-thumb {
  box-sizing: border-box;
  height: 100px;
}
.te-modular-thumbnails .te-slider .slick-slide,
.te-modular-thumbnails .te-slider .slick-slide * {
  outline: none !important;
}
.te-modular-thumbnails .slick-slide .te-thumb {
  position: relative;
}
.te-modular-thumbnails .slick-slide .te-thumb::after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.te-modular-thumbnails .slick-slide .te-thumb .te-img {
  height: 100px;
  width: 100px;
}
.te-modular-thumbnails .slick-slide .te-thumb.active {
  opacity: 0.5;
}
.te-modular-thumbnails .te-arrow {
  opacity: 1;
}
.te-modular-thumbnails .te-arrow.te-vertical.slick-next,
.te-modular-thumbnails .te-arrow.te-vertical.slick-prev {
  transform: translateX(-50%) rotate(90deg);
  opacity: 1;
  height: 50px;
  width: 50px;
  left: 50%;
}
.te-modular-thumbnails .te-arrow.te-horizontal.slick-next,
.te-modular-thumbnails .te-arrow.te-horizontal.slick-prev {
  top: 50%;
  width: 50px;
  height: 50px;
}
.te-modular-thumbnails .te-arrow.slick-next {
  bottom: 0;
  top: unset;
}
.te-modular-thumbnails .te-arrow.slick-prev {
  top: 0;
}
.te-modular-thumbnails.te-horizontal {
  height: 100%;
}
.te-modular-thumbnails.te-horizontal .te-thumbnails {
  width: 100%;
}
.te-modular-thumbnails.te-horizontal .te-slider .slick-slide {
  height: 100%;
}
.te-modular-thumbnails.te-horizontal .te-slider .slick-slide .te-thumb {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.te-modular-thumbnails.te-horizontal .te-slider .slick-slide .te-thumb .te-img {
  height: auto;
  width: auto;
}
.te-modular-thumbnails.te-vertical {
  overflow: hidden;
}
.te-modular-thumbnails.te-vertical .slick-slider {
  max-height: 100%;
}
