.te-columns {
  padding: 30px 0;
}
.te-columns > h1,
.te-columns > h2,
.te-columns > h3 {
  text-align: center;
}
.te-columns h1,
.te-columns h2,
.te-columns h3 {
  font-size: 2em;
  padding-top: 20px;
}
.te-columns h2 {
  font-size: 1.5em;
}
.te-columns h3 {
  font-size: 1.2em;
}
.te-columns .te-columns-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .te-columns .te-columns-wrapper {
    flex-direction: row;
    justify-content: center;
  }
}
@media (min-width: 940px) {
  .te-columns .te-columns-wrapper.small-width {
    max-width: 940px;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .te-columns .te-columns-wrapper.medium-width {
    max-width: 1200px;
    padding: 0;
  }
}
@media (min-width: 960px) {
  .te-columns .te-columns-wrapper.medium-width {
    max-width: 1100px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .te-columns .te-columns-wrapper.full-width {
    padding: 0 20px;
  }
}
.te-columns .te-columns-item {
  flex: 1;
  display: flex;
  position: relative;
  flex-basis: 100%;
}
.te-columns .te-columns-item div {
  width: 100%;
}
.te-columns .te-columns-item .te-article {
  display: flex;
}
.te-columns .te-columns-item.top {
  align-content: flex-start;
}
.te-columns .te-columns-item.top .te-article {
  align-items: flex-start;
}
.te-columns .te-columns-item.center {
  align-content: center;
}
.te-columns .te-columns-item.center .te-article {
  align-items: center;
}
.te-columns .te-columns-item.bottom {
  align-content: flex-end;
}
.te-columns .te-columns-item.bottom .te-article {
  align-items: flex-end;
}
.te-columns .te-article {
  margin: 0;
  align-items: center;
}
.te-columns .te-article .imagewrapper {
  margin: 0;
  width: 100%;
}
.te-columns .te-article-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.te-columns img {
  padding: 30px 20px;
}
