.te-product-listing {
  margin: 0 auto;
  max-width: 1350px;
  position: relative;
  box-sizing: border-box;
}
.te-product-listing * {
  box-sizing: border-box;
}
.te-product-listing .te-product-container {
  padding: 0 20px;
}
.te-product-listing .te-product-container-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.te-product-listing .te-details {
  margin-bottom: 0;
}
.te-product-listing .te-details-brand,
.te-product-listing .te-details-name {
  padding: 0 15px 0 0;
  line-height: 1.4em;
  margin-bottom: 15px;
}
.te-product-listing .te-basic-price {
  margin: 0 0 70px 0;
}
.te-product-listing .te-basic-price.te-mobile {
  margin: 0;
}
.te-product-listing .te-basic-price.te-desktop {
  margin-bottom: 70px;
}
.te-product-listing .te-basic-price-main {
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.te-product-listing .te-basic-price-main-sales {
  margin-left: 20px;
}
.te-product-listing .te-basic-price-main-discount {
  font-size: 1.25em;
  margin-top: 0;
}
.te-product-listing .te-basic-price-main-discount.te-mobile {
  font-size: 0.875em;
}
.te-product-listing .te-basic-price-main-vat {
  margin-left: 10px;
}
.te-product-listing .te-description {
  background-color: #ffffff;
  color: #2c2c2c;
}
.te-product-listing .te-description-popup {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 90%;
  z-index: 9000000;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.te-product-listing .te-description-popup::-webkit-scrollbar {
  display: none;
}
.te-product-listing .te-description-popup.te-mobile {
  position: fixed;
  bottom: unset;
  width: auto;
  height: auto;
  left: 10px;
  right: 10px;
  top: 10px;
}
.te-product-listing .te-description-content {
  padding: 30px 30px 80px;
  width: 90%;
}
.te-product-listing .te-description-link.te-mobile {
  display: block;
}
.te-product-listing .te-button-cross {
  box-sizing: content-box;
  position: absolute;
  right: 10px;
  top: 10px;
}
.te-product-listing .te-color-relations {
  margin: 0 0 20px 0;
}
.te-product-listing .te-color-relations-tile {
  margin: 4px 8px 4px 0;
}
.te-product-listing .te-modular-dropdown.te-desktop {
  margin: 0 0 30px 0;
}
.te-product-listing .te-delivery {
  margin: 0 0 20px 0;
}
.te-product-listing .te-delivery-list {
  margin: 0;
}
