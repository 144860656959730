.te-checkout-form-outer,
.te-styled .te-checkout-form-outer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.te-checkout-form-outer .te-checkout-form-container,
.te-styled .te-checkout-form-outer .te-checkout-form-container {
  height: 40px;
  width: 75%;
}
.te-checkout-form-outer input,
.te-styled .te-checkout-form-outer input {
  border: 0;
  border-radius: 5px;
  height: 100%;
  outline: 0;
  padding: 10px;
  width: 100%;
}
.te-checkout-form-outer button,
.te-styled .te-checkout-form-outer button {
  margin-left: 15px;
  width: calc(25% - 15px);
}
.te-checkout-form-container,
.te-styled .te-checkout-form-container {
  transition: all 0.3s linear;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  display: block;
  margin: 5px 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.te-checkout-form-container.small,
.te-styled .te-checkout-form-container.small {
  display: inline-block;
  height: 50px;
  position: relative;
  width: 50%;
}
.te-checkout-form-container.open,
.te-styled .te-checkout-form-container.open {
  min-height: 200px;
}
.te-checkout-form-container.hide,
.te-styled .te-checkout-form-container.hide {
  min-height: 0;
  height: 0;
  opacity: 0;
}
.te-checkout-form-input,
.te-styled .te-checkout-form-input {
  border-bottom: 1px solid #e7e7e7;
  display: block;
  height: 50px;
  position: relative;
}
.te-checkout-form-input.medium,
.te-styled .te-checkout-form-input.medium {
  border-left: 1px solid #e7e7e7;
  display: inline-block;
  margin-left: -1px;
  width: 50%;
}
.te-checkout-form-input.medium:first-child,
.te-styled .te-checkout-form-input.medium:first-child {
  border-left: 0;
}
.te-checkout-form-input.medium:nth-last-child(2),
.te-styled .te-checkout-form-input.medium:nth-last-child(2),
.te-checkout-form-input.medium:last-child,
.te-styled .te-checkout-form-input.medium:last-child {
  border-bottom: 0;
}
.te-checkout-form-input.small,
.te-styled .te-checkout-form-input.small {
  border-left: 1px solid #e7e7e7;
  display: inline-block;
  margin-left: -1px;
  width: 25%;
}
.te-checkout-form-input input,
.te-styled .te-checkout-form-input input,
.te-checkout-form-input select,
.te-styled .te-checkout-form-input select {
  border: 0;
  font-size: 0.9em;
  height: 100%;
  outline: 0;
  padding: 20px 10px 10px;
  width: 100%;
}
.te-checkout-form-input input::-moz-placeholder,
.te-styled .te-checkout-form-input input::-moz-placeholder,
.te-checkout-form-input select::-moz-placeholder,
.te-styled .te-checkout-form-input select::-moz-placeholder,
.te-checkout-form-input input:-ms-input-placeholder,
.te-styled .te-checkout-form-input input:-ms-input-placeholder,
.te-checkout-form-input select:-ms-input-placeholder,
.te-styled .te-checkout-form-input select:-ms-input-placeholder,
.te-checkout-form-input input:-moz-placeholder,
.te-styled .te-checkout-form-input input:-moz-placeholder,
.te-checkout-form-input select:-moz-placeholder,
.te-styled .te-checkout-form-input select:-moz-placeholder {
  color: #a3a0a0;
}
.te-checkout-form-input input::placeholder,
.te-styled .te-checkout-form-input input::placeholder,
.te-checkout-form-input select::placeholder,
.te-styled .te-checkout-form-input select::placeholder,
.te-checkout-form-input input::-webkit-input-placeholder,
.te-styled .te-checkout-form-input input::-webkit-input-placeholder,
.te-checkout-form-input select::-webkit-input-placeholder,
.te-styled .te-checkout-form-input select::-webkit-input-placeholder {
  color: #a3a0a0;
}
.te-checkout-form-input input:focus::placeholder,
.te-styled .te-checkout-form-input input:focus::placeholder,
.te-checkout-form-input select:focus::placeholder,
.te-styled .te-checkout-form-input select:focus::placeholder,
.te-checkout-form-input input:not(:placeholder-shown)::placeholder,
.te-styled .te-checkout-form-input input:not(:placeholder-shown)::placeholder,
.te-checkout-form-input select:not(:placeholder-shown)::placeholder,
.te-styled .te-checkout-form-input select:not(:placeholder-shown)::placeholder {
  opacity: 0;
}
.te-checkout-form-input input:focus + .te-checkout-form-input-label,
.te-styled .te-checkout-form-input input:focus + .te-checkout-form-input-label,
.te-checkout-form-input select:focus + .te-checkout-form-input-label,
.te-styled .te-checkout-form-input select:focus + .te-checkout-form-input-label,
.te-checkout-form-input input:not(:placeholder-shown) + .te-checkout-form-input-label,
.te-styled .te-checkout-form-input input:not(:placeholder-shown) + .te-checkout-form-input-label,
.te-checkout-form-input select:not(:placeholder-shown) + .te-checkout-form-input-label,
.te-styled .te-checkout-form-input select:not(:placeholder-shown) + .te-checkout-form-input-label {
  opacity: 1;
  transform: translateY(-12px);
}
.te-checkout-form-input select,
.te-styled .te-checkout-form-input select {
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #a3a0a0;
}
.te-checkout-form-input label,
.te-styled .te-checkout-form-input label {
  height: 100%;
  position: relative;
}
.te-checkout-form-input-label,
.te-styled .te-checkout-form-input-label {
  color: #a3a0a0;
  font-size: 0.7em;
  left: 10px;
  opacity: 0;
  position: absolute;
  top: 18px;
  transition: transform 0.3s ease-in;
}
.te-checkout-form-input-label:first-letter,
.te-styled .te-checkout-form-input-label:first-letter {
  text-transform: capitalize;
}
.te-checkout-form-input-icon,
.te-styled .te-checkout-form-input-icon {
  fill: #a3a0a0;
  width: 10px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.te-checkout-form-input.te-selected select,
.te-styled .te-checkout-form-input.te-selected select {
  color: black;
}
.te-checkout-form-input.te-selected .te-checkout-form-input-icon,
.te-styled .te-checkout-form-input.te-selected .te-checkout-form-input-icon {
  fill: black;
}
.te-checkout-form-input.te-selected .te-checkout-form-input-label,
.te-styled .te-checkout-form-input.te-selected .te-checkout-form-input-label {
  opacity: 1;
  transform: translateY(-12px);
}
.te-checkout-form-checkbox,
.te-styled .te-checkout-form-checkbox {
  font-size: 0.875em;
  width: 50%;
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.te-checkout-form-checkbox:hover input ~ .te-checkout-form-checkbox-custom,
.te-styled .te-checkout-form-checkbox:hover input ~ .te-checkout-form-checkbox-custom {
  background-color: #d9d9d9;
}
.te-checkout-form-checkbox input:checked ~ .te-checkout-form-checkbox-custom,
.te-styled .te-checkout-form-checkbox input:checked ~ .te-checkout-form-checkbox-custom {
  background-color: #4f5058;
}
.te-checkout-form-checkbox input:checked ~ .te-checkout-form-checkbox-custom svg,
.te-styled .te-checkout-form-checkbox input:checked ~ .te-checkout-form-checkbox-custom svg {
  display: block;
}
.te-checkout-form-checkbox .te-checkout-form-checkbox-custom,
.te-styled .te-checkout-form-checkbox .te-checkout-form-checkbox-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-checkout-form-checkbox .te-checkout-form-checkbox-custom svg,
.te-styled .te-checkout-form-checkbox .te-checkout-form-checkbox-custom svg {
  display: none;
  width: 13px;
}
.te-checkout-form-checkbox-input,
.te-styled .te-checkout-form-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.te-checkout-form-checkbox-custom,
.te-styled .te-checkout-form-checkbox-custom {
  border-radius: 25%;
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #e7e7e7;
}
.te-checkout-form-checkbox-custom:after,
.te-styled .te-checkout-form-checkbox-custom:after {
  content: '';
  position: absolute;
  display: none;
}
.te-checkout-form-submit,
.te-styled .te-checkout-form-submit {
  margin: 10px 0;
  display: inline-block;
  width: 50%;
}
.te-checkout-form-submit.one_row,
.te-styled .te-checkout-form-submit.one_row {
  width: 100%;
}
.te-checkout-form-submit-button,
.te-styled .te-checkout-form-submit-button {
  display: block;
  background-color: white;
  width: fit-content;
  border: 1px solid #2c2c2c;
  padding: 10px 27px;
  margin-left: auto;
  border-radius: 5px;
  text-transform: uppercase;
  color: #2c2c2c;
  transition: all 0.5s ease;
}
.te-checkout-form-submit-button:hover,
.te-styled .te-checkout-form-submit-button:hover {
  background-color: #2c2c2c;
  color: white;
  cursor: pointer;
}
.te-checkout-form-error,
.te-styled .te-checkout-form-error {
  background-color: white;
  color: #cd5550;
  font-size: 0.8em;
  left: 10px;
  position: absolute;
  top: 2px;
  pointer-events: none;
}
.te-checkout-form-error + .te-checkout-form-input-label,
.te-styled .te-checkout-form-error + .te-checkout-form-input-label {
  opacity: 0;
}
.te-checkout-form-error.generic,
.te-styled .te-checkout-form-error.generic {
  bottom: -30px;
  top: unset;
}
.te-checkout-tooltip,
.te-styled .te-checkout-tooltip {
  align-items: center;
  background-color: #a3a0a0;
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: 0.8em;
  font-weight: 600;
  height: 18px;
  justify-content: center;
  width: 18px;
}
.te-checkout-address-summary,
.te-styled .te-checkout-address-summary {
  padding: 10px;
  height: 0;
  min-height: unset;
}
.te-checkout-address-summary.visible,
.te-styled .te-checkout-address-summary.visible {
  height: auto;
}
