.te-button {
  color: white;
  border: none;
  border-radius: 0;
  display: inline-block;
  overflow: visible;
  font-weight: 200;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: 0.3s;
  min-width: 200px;
  position: relative;
  vertical-align: middle;
  -webkit-appearance: none;
  z-index: 0;
}
.te-button:hover {
  opacity: 0.8;
}
.te-button-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.te-button-text {
  padding: 0 40px;
  color: #fff;
  line-height: 30px;
  white-space: nowrap;
  letter-spacing: 0.5px;
}
.te-button-outline {
  background-color: transparent;
  border: 1px solid #333;
  color: #333;
}
.te-button-outline:hover {
  color: #fff;
}
.te-button-default {
  font-size: 1em;
  min-height: 30px;
  padding: 0;
  line-height: 4em;
}
.te-button-small {
  font-size: 10px;
  min-height: 14px;
  padding: 2px 19px;
  line-height: 2;
}
.te-button.disabled .te-button-text {
  color: #a3a0a0;
}
.te-buy-button-small {
  border-radius: 0;
  padding: 10px 30px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
  -webkit-backface-visibility: hidden;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.8;
  display: inline-block;
  margin: 5px auto 0;
  width: 100%;
}
.te-button:hover {
  color: white;
  border: none;
}
.te-button:not(:disabled) {
  cursor: pointer;
}
.te-button:hover,
.te-button:focus {
  opacity: 0.7;
  outline: none;
  text-decoration: none;
}
