.te-button-clear {
  border: none;
  background: transparent;
  cursor: pointer;
  color: inherit;
  display: inline-flex;
  font: inherit;
  height: auto;
  line-height: inherit;
  margin: 0;
  overflow: visible;
  outline: none;
  padding: 0;
  text-decoration: none;
  text-align: inherit;
  width: auto;
  white-space: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
