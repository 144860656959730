.te-profile {
  width: 100%;
  height: 60vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  position: relative;
}
.te-profile:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  z-index: -1;
}
.te-profile .te-profile-title {
  font-size: 9vw;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .te-profile .te-profile-title {
    font-size: 3em;
  }
}
.te-profile .te-profile-handle {
  color: white;
  margin-top: 0;
}
.te-profile .te-profile-bio {
  font-size: 0.8em;
  display: block;
  margin: 0 auto;
  color: #525860;
  width: 940px;
  max-width: 100%;
  text-align: center;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .te-profile .te-profile-bio {
    padding: 15px 0;
  }
}
