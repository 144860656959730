.te-article {
  max-width: 940px;
  margin: 20px auto 0;
  box-sizing: border-box;
}
.te-article .imagewrapper {
  text-align: center;
  margin: 20px 0;
}
.te-article .imagewrapper img {
  max-width: 100%;
}
.te-article .imagewrapper + em {
  margin: -20px 0 20px;
  color: #525860;
  display: block;
  font-size: 0.8em;
  text-align: right;
}
@media (max-width: 479px) {
  .te-article {
    padding: 15px;
  }
  .te-article .imagewrapper {
    margin: 20px -15px;
    width: 100vw;
    max-width: none;
  }
}
@media (min-width: 480px) {
  .te-article {
    margin-top: 20px;
    padding: 20px;
  }
  .te-article .imagewrapper {
    margin: 50px -20px 30px;
  }
}
@media (min-width: 960px) {
  .te-article {
    margin-top: 20px;
    padding: 20px;
  }
  .te-article .imagewrapper {
    margin-top: 50px;
    margin-left: calc((960px - 100vw) / 2);
    margin-right: calc((960px - 100vw) / 2);
    margin-bottom: 30px;
  }
}
@media (min-width: 1280px) {
  .te-article {
    margin-top: 20px;
    padding: 20px;
  }
  .te-article .imagewrapper {
    margin: 80px -150px 30px;
  }
}
.te-article p {
  white-space: normal;
}
.te-article li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.te-article .method-list {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.te-article .method-list-group {
  margin-top: 8px;
  border-top: 1px solid #e1e8ed;
  list-style: none;
}
.te-article .method-list-item {
  padding: 17px 0;
  border-bottom: 1px solid #f0f4f7;
  line-height: 24px;
}
.te-article .method-list-item::after,
.te-article .method-list-item::before {
  content: '';
  display: table;
}
.te-article .method-list-item-label {
  position: relative;
  z-index: 5;
  float: left;
  text-align: right;
  width: 180px;
  font-size: 13px;
  line-height: 21px;
  word-break: break-all;
  white-space: normal;
  margin: 0;
  color: #292e31;
}
.te-article .method-list-item-validation {
  display: block;
  color: #939da3;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  word-break: normal;
}
.te-article .method-list-item-description {
  position: relative;
  z-index: 10;
  background: #fff;
  margin: 0 0 0 200px;
  font-size: 13px;
  line-height: 21px;
}
.te-article .method-list-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}
.te-article code {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 5px;
  background: #fafcfc;
  border: 1px solid #f0f4f7;
  border-radius: 4px;
  color: #b93d6a;
  font-family: Source Code Pro, Menlo, monospace;
  font-size: 13px;
  line-height: 20px;
}
