.te-product-description-classic {
  cursor: pointer;
  border-top: 1px solid #bdc3c7;
  border-bottom: 1px solid #bdc3c7;
  display: block;
}
.te-product-description-classic-title {
  text-transform: capitalize;
  text-align: center;
  display: block;
  font-size: 0.85em;
  padding: 10px;
  position: relative;
}
.te-product-description-classic-title svg {
  width: 10px;
}
.te-product-description-classic-content {
  font-size: 0.85em;
  line-height: 1.7;
  overflow-y: auto;
  -webkit-transition: 1s;
  transition: 1s;
  max-height: 0;
  margin: 0 10px;
  letter-spacing: 0;
  text-align: left;
}
.te-product-description-classic-content p {
  font-size: inherit;
  margin: 5px 0;
}
.te-product-description-classic-content.active {
  max-height: 500px;
  margin: 10px;
}
.te-product-description-classic.active svg {
  transform: rotate(180deg);
}
