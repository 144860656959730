.te-checkout-payment-summary,
.te-styled .te-checkout-payment-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.te-checkout-payment-summary-control-row,
.te-styled .te-checkout-payment-summary-control-row {
  display: flex;
  margin-bottom: 5px;
}
.te-checkout-payment-summary-toggle-link,
.te-styled .te-checkout-payment-summary-toggle-link {
  cursor: pointer;
  text-decoration: underline;
}
.te-checkout-payment-summary-total-price,
.te-styled .te-checkout-payment-summary-total-price {
  font-weight: bold;
}
.te-checkout-cart-summary-rows-container,
.te-styled .te-checkout-cart-summary-rows-container {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.8em;
}
.te-checkout-cart-summary-row,
.te-styled .te-checkout-cart-summary-row {
  display: flex;
  justify-content: space-between;
}
.te-checkout-cart-summary-row .te-label,
.te-styled .te-checkout-cart-summary-row .te-label {
  text-transform: capitalize;
}
.te-checkout-cart-summary-separator,
.te-styled .te-checkout-cart-summary-separator {
  height: 1px;
  border-top: 1px solid #e7e7e7;
  margin: 10px 0;
}
.te-checkout-stripe-error,
.te-styled .te-checkout-stripe-error {
  color: #cd5550;
  text-align: center;
  margin-top: 20px;
}
.te-checkout-stripe-error,
.te-styled .te-checkout-stripe-error {
  color: #cd5550;
  text-align: center;
  margin-top: 20px;
}
.te-checkout-payment-view,
.te-styled .te-checkout-payment-view {
  display: flex;
  flex-direction: column;
}
.te-checkout-tooltip,
.te-styled .te-checkout-tooltip {
  left: calc(50% + 10px);
  position: absolute;
  bottom: 35px;
}
.te-checkout-tooltip.desktop,
.te-styled .te-checkout-tooltip.desktop {
  right: 10px;
  left: unset;
  bottom: 18px;
}
.te-checkout-payment-view,
.te-styled .te-checkout-payment-view {
  display: flex;
  flex-direction: column;
}
.te-checkout-payment-toggle,
.te-styled .te-checkout-payment-toggle {
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  padding: 15px 0;
}
.te-checkout-payment-toggle > span,
.te-styled .te-checkout-payment-toggle > span {
  padding-right: 10px;
  text-transform: capitalize;
}
.te-checkout-payment-toggle button,
.te-styled .te-checkout-payment-toggle button {
  text-decoration: underline;
}
.te-checkout-payment-toggle button span::first-letter,
.te-styled .te-checkout-payment-toggle button span::first-letter {
  text-transform: capitalize;
}
.te-checkout-payment-total,
.te-styled .te-checkout-payment-total {
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin-bottom: 15px;
}
.te-checkout-container .__react_component_tooltip,
.te-styled .te-checkout-container .__react_component_tooltip {
  max-width: 300px !important;
}
.te-checkout-stripe-wrapper,
.te-styled .te-checkout-stripe-wrapper {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1000px;
  overflow: hidden;
  width: 100%;
}
.te-checkout-stripe-input,
.te-styled .te-checkout-stripe-input {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 10px;
}
.te-checkout-stripe-input.small,
.te-styled .te-checkout-stripe-input.small {
  border-bottom: 0;
  border-left: 1px solid #e7e7e7;
  display: inline-block;
  margin-left: -1px;
  margin-bottom: -5px;
  width: 50%;
}
.te-checkout-stripe-input.small:nth-last-of-type(2),
.te-styled .te-checkout-stripe-input.small:nth-last-of-type(2) {
  border-left: 0;
}
.te-checkout-stripe-input.small:last-of-type,
.te-styled .te-checkout-stripe-input.small:last-of-type {
  border: 0;
}
.te-checkout-stripe-card-icons,
.te-styled .te-checkout-stripe-card-icons {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
}
.te-checkout-stripe-card-icons svg,
.te-styled .te-checkout-stripe-card-icons svg {
  height: 30px;
}
.te-checkout-stripe-card-icons.xsmall svg,
.te-styled .te-checkout-stripe-card-icons.xsmall svg {
  height: 20px;
}
.te-checkout-stripe-card-icons.small svg,
.te-styled .te-checkout-stripe-card-icons.small svg {
  height: 24px;
}
.te-checkout-stripe-card,
.te-styled .te-checkout-stripe-card {
  margin: 0 5px;
}
.te-stripe-legal,
.te-styled .te-stripe-legal {
  background-color: white;
  color: #303248;
  font-size: 0.8em;
  line-height: 1.7;
  margin: 0 auto;
  max-width: 305px;
  text-align: center;
}
.te-stripe-legal a,
.te-styled .te-stripe-legal a {
  color: black;
}
.te-form-input-group .te-stripe-container:first-child,
.te-styled .te-form-input-group .te-stripe-container:first-child {
  margin-right: 20px;
}
div.te-checkout-container.te-klarna,
.te-styled div.te-checkout-container.te-klarna {
  padding: 0;
}
div.te-checkout-container.te-klarna .te-klarna-container.after-redirect,
.te-styled div.te-checkout-container.te-klarna .te-klarna-container.after-redirect {
  margin-top: 20px;
  height: 140px;
}
div.te-checkout-container .te-klarna-iframe.after-redirect,
.te-styled div.te-checkout-container .te-klarna-iframe.after-redirect {
  display: none;
}
div.te-checkout-container .te-klarna-loader,
.te-styled div.te-checkout-container .te-klarna-loader {
  display: none;
}
div.te-checkout-container .te-klarna-loader.after-redirect,
.te-styled div.te-checkout-container .te-klarna-loader.after-redirect {
  display: block;
}
.te-checkout-stripe-pay-button,
.te-styled .te-checkout-stripe-pay-button {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-size: 0.9em;
  line-height: 2.7em;
  margin: 0 auto;
  overflow: hidden;
  max-width: 310px;
}
.te-checkout-stripe-pay-button.te-desktop,
.te-styled .te-checkout-stripe-pay-button.te-desktop {
  max-width: 410px;
}
.te-checkout-stripe-pay-button .te-button,
.te-styled .te-checkout-stripe-pay-button .te-button {
  width: 100%;
  padding: 7px;
}
.te-checkout-stripe-pay-button-loader .fade-in,
.te-styled .te-checkout-stripe-pay-button-loader .fade-in {
  font-size: 1em;
}
.te-checkout-stripe-pay-button-loader .fade-in .te-loader-pulse-wrapper,
.te-styled .te-checkout-stripe-pay-button-loader .fade-in .te-loader-pulse-wrapper {
  height: 30px;
  justify-content: center;
}
.te-checkout-stripe-pay-button-loader .fade-in .te-loader-pulse-wrapper .te-loader-pulse-text,
.te-styled .te-checkout-stripe-pay-button-loader .fade-in .te-loader-pulse-wrapper .te-loader-pulse-text {
  margin: 0;
}
.te-checkout-stripe-pay-button-primary,
.te-styled .te-checkout-stripe-pay-button-primary {
  display: flex;
  justify-content: center;
}
.te-checkout-stripe-pay-button-icon,
.te-styled .te-checkout-stripe-pay-button-icon {
  display: flex;
  align-items: center;
  margin: 0;
  min-width: auto;
  min-height: unset;
}
.te-checkout-stripe-pay-button-icon svg,
.te-styled .te-checkout-stripe-pay-button-icon svg {
  height: 100%;
}
.te-checkout-stripe-pay-button .te-button-text,
.te-styled .te-checkout-stripe-pay-button .te-button-text {
  display: block;
}
.te-checkout-stripe-pay-button-icon-wrapper,
.te-styled .te-checkout-stripe-pay-button-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-checkout-stripe-pay-button-icon-wrapper .te-button-text,
.te-styled .te-checkout-stripe-pay-button-icon-wrapper .te-button-text {
  padding: 0 0 0 8px;
}
