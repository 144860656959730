.te-horizontal-menu {
  display: block;
  width: 100vw;
  overflow-x: auto;
  position: relative;
  padding: 0 20px;
  white-space: nowrap;
  height: auto;
  text-align: center;
}
.te-horizontal-menu::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .te-horizontal-menu {
    width: 100%;
  }
}
.te-horizontal-menu .te-horizontal-menu-item {
  display: inline-block;
  padding: 0 5px;
  margin: 0;
  color: #bdc3c7;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s ease;
}
.te-horizontal-menu .te-horizontal-menu-item:hover {
  color: #525860;
}
.te-horizontal-menu .te-horizontal-menu-item span {
  display: block;
  padding: 4px 8px;
}
.te-horizontal-menu .te-horizontal-menu-item.selected,
.te-horizontal-menu .te-horizontal-menu-item .te-link {
  padding: 0 5px;
}
.te-horizontal-menu .te-horizontal-menu-item.selected.selected,
.te-horizontal-menu .te-horizontal-menu-item .te-link.selected {
  background: transparent;
  border: 1px solid #bdc3c7;
  color: #bdc3c7;
}
