.te-hero-component {
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
}
.te-hero-component.short {
  min-height: 50vh;
}
.te-hero-component.short .te-hero-blurb {
  min-height: 40vh;
}
.te-hero-component.tall {
  min-height: 90vh;
}
.te-hero-component.left {
  justify-content: flex-start;
}
.te-hero-component.center {
  justify-content: center;
}
.te-hero-component.right {
  justify-content: flex-end;
}
.te-hero-component .te-hero-blurb {
  min-height: 50vh;
  width: 70%;
  overflow: hidden;
  margin: 0 20px;
  color: #fff;
  z-index: 1;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb {
    width: 50%;
  }
}
.te-hero-component .te-hero-blurb audio,
.te-hero-component .te-hero-blurb canvas,
.te-hero-component .te-hero-blurb img,
.te-hero-component .te-hero-blurb svg,
.te-hero-component .te-hero-blurb video {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}
.te-hero-component .te-hero-blurb .te-link,
.te-hero-component .te-hero-blurb .te-product {
  display: block;
  height: 100%;
  padding: 0;
  width: 100%;
}
.te-hero-component .te-hero-blurb .te-product {
  margin: 0;
  background: transparent;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb .te-product {
    padding: 20px 0;
    display: flex;
  }
}
.te-hero-component .te-hero-blurb .te-product-image {
  height: 75%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb .te-product-image {
    height: 100%;
  }
  .te-hero-component .te-hero-blurb .te-product-image img {
    max-height: 75%;
  }
}
.te-hero-component .te-hero-blurb .te-product-details {
  margin: 0;
  padding: 10px;
  height: 25%;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb .te-product-details {
    display: flex;
    height: 100%;
  }
}
.te-hero-component .te-hero-blurb .te-product .te-product-basic-info {
  height: 100%;
  margin: 0;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb .te-product .te-product-basic-info {
    height: auto;
    margin-bottom: 25px;
  }
}
.te-hero-component .te-hero-blurb .te-product .te-product-name {
  font-size: 1em;
}
.te-hero-component .te-hero-blurb .te-product .te-product-brand {
  margin-bottom: 0;
}
.te-hero-component .te-hero-blurb .te-product .te-product-sales-price {
  display: inline-block;
}
.te-hero-component .te-hero-blurb .te-product .te-product-regular-price {
  display: inline-block;
  margin-left: 20px;
}
.te-hero-component .te-hero-blurb .te-product .te-dropdown-container,
.te-hero-component .te-hero-blurb .te-product .te-button {
  display: none;
}
@media (min-width: 768px) {
  .te-hero-component .te-hero-blurb .te-product .te-dropdown-container,
  .te-hero-component .te-hero-blurb .te-product .te-button {
    display: block;
  }
}
.te-hero-component .te-hero-blurb .te-product .te-product-info-container,
.te-hero-component .te-hero-blurb .te-product .te-product-description {
  display: none;
}
.te-hero-component .te-hero-blurb .te-article {
  margin: 0;
  text-shadow: 1px 1px 19px #85888d;
}
.te-hero-component .te-hero-blurb .te-article h1,
.te-hero-component .te-hero-blurb .te-article h2,
.te-hero-component .te-hero-blurb .te-article h3 {
  color: inherit;
  font-family: inherit;
}
.te-hero-component .te-hero-blurb .te-article p,
.te-hero-component .te-hero-blurb .te-article li {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
}
.te-hero-component .te-hero-blurb .te-img {
  width: 100%;
  height: 100%;
  background-size: contain;
}
.te-hero-component .te-hero-component_background-image.te-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
