.tipser-ie .te-container.te-container-vertical {
  display: block !important;
}
.tipser-ie .te-container-item {
  padding: 5px;
}
.tipser-ie .te-related-page-img-wrapper {
  overflow: hidden;
}
.tipser-ie .te-related-page-img-wrapper img {
  width: auto;
  max-width: none;
  margin-left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
}
.tipser-element a:not(.tipser-button) {
  color: #525860;
}
.tipser-element a:not(.tipser-button):hover {
  text-decoration: none;
}
