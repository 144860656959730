.te-collection-container {
  width: 100%;
  position: relative;
}
.te-collection-container .te-collection {
  width: 100%;
  margin: 20px auto;
  background: inherit;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.te-collection-container .te-collection-small .te-product-card {
  width: 50%;
}
.te-collection-container .te-collection-large .te-product-card {
  width: 25%;
}
.te-collection-container .te-collection-extra-large .te-product-card {
  width: 20%;
}
.te-collection-container .te-collection-extra-large.carousel .te-product-card,
.te-collection-container .te-collection-large.carousel .te-product-card,
.te-collection-container .te-collection-small.carousel .te-product-card {
  width: 100%;
}
.te-collection-container .te-collection .te-product-card .te-button:not(.disabled):hover {
  color: white;
  opacity: 0.8;
  border-color: #222;
}
.te-collection-container .loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hide > * {
  display: none;
}
