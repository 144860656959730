.te-style-with,
.te-styled .te-product-page .te-style-with {
  color: #444444;
  padding: 0 10px;
}
.te-style-with-blank-space,
.te-styled .te-product-page .te-style-with-blank-space {
  height: 40px;
}
.te-style-with-blank-space.te-desktop,
.te-styled .te-product-page .te-style-with-blank-space.te-desktop {
  height: 70px;
}
.te-style-with-inner,
.te-styled .te-product-page .te-style-with-inner {
  margin-bottom: 20px;
}
.te-style-with-inner .slick-track,
.te-styled .te-product-page .te-style-with-inner .slick-track {
  margin-left: 0;
}
.te-style-with-inner.te-desktop,
.te-styled .te-product-page .te-style-with-inner.te-desktop {
  margin-bottom: 65px;
  margin-top: 50px;
}
.te-style-with-title,
.te-styled .te-product-page .te-style-with-title {
  color: #4f5058;
  font-weight: 500;
  font-size: 1em;
  margin: 15px 10px;
  padding: 0;
}
.te-style-with-products-container,
.te-styled .te-product-page .te-style-with-products-container {
  display: block;
  margin: 10px;
  font-size: 12px;
  font-weight: 500;
  padding-right: 5px;
  white-space: nowrap;
  width: 100%;
}
.te-style-with-products-container:hover,
.te-styled .te-product-page .te-style-with-products-container:hover {
  cursor: pointer;
}
.te-style-with-products-image-container,
.te-styled .te-product-page .te-style-with-products-image-container {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  position: relative;
}
.te-style-with-products-image-container::after,
.te-styled .te-product-page .te-style-with-products-image-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 999999;
}
.te-style-with-products-image,
.te-styled .te-product-page .te-style-with-products-image {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.te-style-with-products-image.cover,
.te-styled .te-product-page .te-style-with-products-image.cover {
  object-fit: cover;
}
.te-style-with-products-image.contain,
.te-styled .te-product-page .te-style-with-products-image.contain {
  object-fit: contain;
}
.te-style-with-products-brand,
.te-styled .te-product-page .te-style-with-products-brand {
  color: #a3a0a0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.te-style-with-products-brand.te-desktop,
.te-styled .te-product-page .te-style-with-products-brand.te-desktop {
  margin-bottom: 2px;
}
.te-style-with-products-name,
.te-styled .te-product-page .te-style-with-products-name {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4f5058;
}
.te-style-with-products-name.te-desktop,
.te-styled .te-product-page .te-style-with-products-name.te-desktop {
  margin-bottom: 2px;
}
.te-style-with-products-price-container,
.te-styled .te-product-page .te-style-with-products-price-container {
  display: flex;
}
.te-style-with-products-no-sales,
.te-styled .te-product-page .te-style-with-products-no-sales {
  color: #4f5058;
}
.te-style-with-products-price-sales,
.te-styled .te-product-page .te-style-with-products-price-sales {
  color: #ec877b;
  margin-left: 10px;
}
.te-style-with-products-price-regular,
.te-styled .te-product-page .te-style-with-products-price-regular {
  text-decoration: line-through;
}
.te-style-with-arrow,
.te-styled .te-product-page .te-style-with-arrow {
  z-index: 1;
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: #000;
  padding: 0;
  border: none;
  outline: none;
  opacity: 1;
}
.te-style-with-arrow.slick-next,
.te-styled .te-product-page .te-style-with-arrow.slick-next {
  top: 42%;
  left: calc(100% - 5px);
  transform: translate(-100%, -50%);
}
.te-style-with-arrow.slick-prev,
.te-styled .te-product-page .te-style-with-arrow.slick-prev {
  left: 2px;
  top: 42%;
  transform: translate(0, -50%);
}
