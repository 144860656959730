.te-cart-page,
.te-styled .te-cart-page {
  height: 100%;
}
.te-cart-page-button,
.te-styled .te-cart-page-button {
  margin: 20px 0;
  width: 100%;
}
.te-cart-page-button-primary,
.te-styled .te-cart-page-button-primary {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  line-height: 2.7em;
  margin: 0 auto;
  overflow: hidden;
  max-width: 400px;
  border-radius: 5px;
}
.te-cart-page-button-primary .te-button-text,
.te-styled .te-cart-page-button-primary .te-button-text {
  padding: 0 7px;
}
.te-cart-page-button-icon-wrapper,
.te-styled .te-cart-page-button-icon-wrapper {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-cart-page-button-icon-wrapper svg,
.te-styled .te-cart-page-button-icon-wrapper svg {
  height: 100%;
}
.te-cart-page-columns-layout,
.te-styled .te-cart-page-columns-layout {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
}
.te-cart-page-columns-layout.te-mobile,
.te-styled .te-cart-page-columns-layout.te-mobile {
  flex-direction: column;
}
.te-cart-page-columns-layout.te-mobile .te-cart-page-column,
.te-styled .te-cart-page-columns-layout.te-mobile .te-cart-page-column,
.te-cart-page-columns-layout.te-mobile .te-cart-page-column-small,
.te-styled .te-cart-page-columns-layout.te-mobile .te-cart-page-column-small {
  max-width: 100%;
  padding: 0;
  width: 100%;
}
.te-cart-page-columns-layout.te-mobile .te-cart-page-heading.desktop.toggle,
.te-styled .te-cart-page-columns-layout.te-mobile .te-cart-page-heading.desktop.toggle {
  padding-left: 15px;
  margin: 0;
}
.te-cart-page-column,
.te-styled .te-cart-page-column {
  width: calc(100% - 35px);
}
.te-cart-page-column-small,
.te-styled .te-cart-page-column-small {
  padding-left: 35px;
  width: 100%;
  max-width: 430px;
}
.te-cart-page-payment-icons,
.te-styled .te-cart-page-payment-icons {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.te-cart-page-payment-icons .te-checkout-stripe-card,
.te-styled .te-cart-page-payment-icons .te-checkout-stripe-card {
  margin: 0 20px 0 0;
}
.te-cart-page-payment-icons svg,
.te-styled .te-cart-page-payment-icons svg {
  height: 30px;
}
.te-cart-page-payment-title,
.te-styled .te-cart-page-payment-title {
  text-transform: capitalize;
  font-size: 0.8em;
  color: #4f5058;
  font-weight: 400;
  padding: 0 15px 10px;
}
.te-cart-page-checkout-button,
.te-styled .te-cart-page-checkout-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.te-cart-page-products-list.te-mobile,
.te-styled .te-cart-page-products-list.te-mobile {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0 auto 20px;
}
.te-cart-page-summary-total,
.te-styled .te-cart-page-summary-total {
  margin: 0 auto;
  width: 100%;
  color: #303248;
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
  line-height: 1.7;
}
.te-cart-page-stand-alone,
.te-styled .te-cart-page-stand-alone {
  background-color: #f5f5f5;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px 10px;
  margin-bottom: 30px;
}
.te-cart-page-stand-alone .te-cart-page-heading,
.te-styled .te-cart-page-stand-alone .te-cart-page-heading {
  padding-left: 15px;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 400;
  text-transform: capitalize;
}
.te-cart-page-stand-alone .te-cart-page-heading.toggle,
.te-styled .te-cart-page-stand-alone .te-cart-page-heading.toggle {
  margin-bottom: 15px;
}
.te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal,
.te-styled .te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal {
  color: #303248;
  font-size: 0.8em;
  margin: 0 auto;
  max-width: 305px;
  background: transparent;
  padding: 15px 0;
}
.te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal a,
.te-styled .te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal a {
  color: black;
}
.te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal .te-cart-page-stripe-wrapper,
.te-styled .te-cart-page-stand-alone .te-cart-page-container.te-stripe-legal .te-cart-page-stripe-wrapper {
  background-color: transparent;
}
.te-cart-page-stand-alone .te-cart-page-stripe-wrapper,
.te-styled .te-cart-page-stand-alone .te-cart-page-stripe-wrapper {
  background-color: transparent;
}
.te-cart-page-stand-alone .te-cart-page-legal,
.te-styled .te-cart-page-stand-alone .te-cart-page-legal {
  background-color: #e7e7e7;
}
