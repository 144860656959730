.te-modular-cart,
.te-styled .te-modular-cart {
  background-color: #f5f5f5;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}
.te-modular-cart-columns-layout,
.te-styled .te-modular-cart-columns-layout {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
}
.te-modular-cart-columns-layout.mobile,
.te-styled .te-modular-cart-columns-layout.mobile {
  flex-direction: column;
}
.te-modular-cart-columns-layout.mobile .te-modular-cart-column,
.te-styled .te-modular-cart-columns-layout.mobile .te-modular-cart-column,
.te-modular-cart-columns-layout.mobile .te-modular-cart-column-small,
.te-styled .te-modular-cart-columns-layout.mobile .te-modular-cart-column-small {
  max-width: 100%;
  padding: 0;
  width: 100%;
}
.te-modular-cart-columns-layout.mobile .te-modular-cart-heading.desktop.toggle,
.te-styled .te-modular-cart-columns-layout.mobile .te-modular-cart-heading.desktop.toggle {
  padding-left: 15px;
  margin: 0;
}
.te-modular-cart-column,
.te-styled .te-modular-cart-column {
  padding-left: 35px;
  width: calc(100% - 35px);
}
.te-modular-cart-column-small,
.te-styled .te-modular-cart-column-small {
  width: 100%;
  max-width: 430px;
}
.te-modular-cart-loader,
.te-styled .te-modular-cart-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 33vh;
}
.te-modular-cart.desktop,
.te-styled .te-modular-cart.desktop {
  padding: 10px 18px;
}
.te-modular-cart.desktop .te-modular-cart-animation,
.te-styled .te-modular-cart.desktop .te-modular-cart-animation {
  padding: 150px 0;
}
.te-modular-cart-outer-wrapper,
.te-styled .te-modular-cart-outer-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}
.te-modular-cart-button,
.te-styled .te-modular-cart-button {
  border-radius: 5px;
  border: 0;
  background-color: #222;
  color: white;
  font-size: 0.76em;
  font-weight: 500;
  height: 40px;
  text-transform: uppercase;
}
.te-modular-cart-button:disabled,
.te-styled .te-modular-cart-button:disabled {
  background: #a3a0a0;
}
.te-modular-cart-container,
.te-styled .te-modular-cart-container {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 20px auto 20px;
  max-width: 1000px;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  transition: all 0.5s ease;
  width: 100%;
}
.te-modular-cart-container *,
.te-styled .te-modular-cart-container * {
  box-sizing: border-box;
}
.te-modular-cart-container.desktop,
.te-styled .te-modular-cart-container.desktop {
  padding: 15px 30px;
}
.te-modular-cart-container.desktop .toggle-edit,
.te-styled .te-modular-cart-container.desktop .toggle-edit {
  top: 15px;
  right: 30px;
}
.te-modular-cart-container.small,
.te-styled .te-modular-cart-container.small {
  margin-left: 0;
  max-width: 460px;
}
.te-modular-cart-container.hide,
.te-styled .te-modular-cart-container.hide {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.te-modular-cart-container .toggle-edit,
.te-styled .te-modular-cart-container .toggle-edit {
  position: absolute;
  top: 10px;
  right: 15px;
}
.te-modular-cart-container p,
.te-styled .te-modular-cart-container p {
  font-size: 0.8em;
}
.te-modular-cart-container.te-similar,
.te-styled .te-modular-cart-container.te-similar {
  padding: 15px 0 15px 10px;
  max-width: 1100px;
}
.te-modular-cart-container.te-similar.desktop,
.te-styled .te-modular-cart-container.te-similar.desktop {
  padding: 15px 0 15px 20px;
}
.te-modular-cart-animation,
.te-styled .te-modular-cart-animation {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}
.te-modular-cart-animation p,
.te-styled .te-modular-cart-animation p {
  font-size: 1em;
}
.te-modular-cart-similar-products,
.te-styled .te-modular-cart-similar-products {
  min-height: 560px;
}
.te-modular-cart-similar-products.te-xxsmall,
.te-styled .te-modular-cart-similar-products.te-xxsmall {
  min-height: 440px;
}
.te-modular-cart-similar-products.te-xsmall,
.te-styled .te-modular-cart-similar-products.te-xsmall {
  min-height: 500px;
}
.te-modular-cart-similar-products.te-small,
.te-styled .te-modular-cart-similar-products.te-small {
  min-height: 550px;
}
.te-modular-cart-similar-products.te-medium,
.te-styled .te-modular-cart-similar-products.te-medium {
  min-height: 540px;
}
.te-modular-cart-similar-products-images,
.te-styled .te-modular-cart-similar-products-images {
  min-height: 400px;
}
.te-modular-cart-similar-products-images.te-xxsmall,
.te-styled .te-modular-cart-similar-products-images.te-xxsmall {
  min-height: 270px;
}
.te-modular-cart-similar-products-images.te-xsmall,
.te-styled .te-modular-cart-similar-products-images.te-xsmall,
.te-modular-cart-similar-products-images.te-small,
.te-styled .te-modular-cart-similar-products-images.te-small {
  min-height: 400px;
}
.te-modular-cart-similar-products-images.te-medium,
.te-styled .te-modular-cart-similar-products-images.te-medium {
  min-height: 410px;
}
.te-modular-cart-similar-products-text,
.te-styled .te-modular-cart-similar-products-text {
  text-align: center;
  margin: 100px 15px;
}
.te-modular-cart-similar-products-text span,
.te-styled .te-modular-cart-similar-products-text span {
  display: block;
  line-height: 1.5em;
  padding-bottom: 1.5em;
}
.te-modular-cart-similar-products-text.desktop,
.te-styled .te-modular-cart-similar-products-text.desktop {
  margin: 120px 20px;
}
.te-modular-cart-similar-products-text.desktop span,
.te-styled .te-modular-cart-similar-products-text.desktop span {
  display: inline-block;
  padding-right: 5px;
}
.te-modular-cart-heading,
h2.te-modular-cart-heading {
  font-size: 1em;
  font-weight: normal;
  font-family: inherit;
  margin: 0.5em auto 1em;
  max-width: 1000px;
  padding: 0;
  text-transform: capitalize;
}
.te-modular-cart-heading.toggle,
h2.te-modular-cart-heading.toggle {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  max-width: 460px;
  padding-left: 15px;
  width: 100%;
}
.te-modular-cart-heading.toggle h2,
h2.te-modular-cart-heading.toggle h2 {
  font-family: inherit;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}
.te-modular-cart-heading.toggle svg,
h2.te-modular-cart-heading.toggle svg {
  margin-left: 20px;
  width: 10px;
  transform: rotate(180deg);
}
.te-modular-cart-heading.toggle svg.open,
h2.te-modular-cart-heading.toggle svg.open {
  transform: rotate(0deg);
}
.te-modular-cart-stand-alone {
  background-color: #f5f5f5;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px 10px;
}
.te-modular-cart-stand-alone .te-modular-cart-heading {
  padding-left: 15px;
  margin: 0 auto;
}
.te-modular-cart-stand-alone .te-modular-cart-heading.toggle {
  margin-bottom: 15px;
}
.te-modular-cart-stand-alone .te-modular-cart-container.te-stripe-legal {
  color: #303248;
  font-size: 0.8em;
  margin: 0 auto;
  max-width: 305px;
  background: transparent;
  padding: 15px 0;
}
.te-modular-cart-stand-alone .te-modular-cart-container.te-stripe-legal a {
  color: black;
}
.te-modular-cart-stand-alone .te-modular-cart-container.te-stripe-legal .te-modular-cart-stripe-wrapper {
  background-color: transparent;
}
.te-modular-cart-stand-alone .te-modular-cart-stripe-wrapper {
  background-color: transparent;
}
.te-modular-cart-stand-alone .te-modular-cart-legal {
  background-color: #e7e7e7;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  border: 0;
  -webkit-box-shadow: 0 0 0 50px white inset;
}
.te-dialog-inner .te-modular-cart {
  height: 100%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
