.te-popup.te-added-to-cart,
.te-styled .te-popup.te-added-to-cart {
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  width: 390px;
}
.te-popup.te-added-to-cart.expanded,
.te-styled .te-popup.te-added-to-cart.expanded {
  max-height: 90vh;
}
.te-popup.te-added-to-cart .te-main-content-container,
.te-styled .te-popup.te-added-to-cart .te-main-content-container {
  margin-bottom: 10px;
  display: flex;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  max-height: 130px;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-name,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-name {
  margin-bottom: 5px;
  color: #4f5058;
  line-height: 16px;
  font-weight: 500;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-brand,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-brand {
  margin-bottom: 5px;
  color: #a3a0a0;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-variant,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-variant {
  margin-top: auto;
  color: #303248;
  display: flex;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-variant-text,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-variant-text {
  text-transform: uppercase;
  margin-right: 20px;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price-main,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price-main {
  display: flex;
  margin-bottom: 5px;
  color: #4f5058;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price {
  margin-right: 10px;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price-vat-info,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-price-vat-info {
  font-size: 0.8em;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-regular-price,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-regular-price {
  text-decoration: line-through;
}
.te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-sales-price,
.te-styled .te-popup.te-added-to-cart .te-main-content-container .te-popup-product-basic-info .te-product-price-container .te-product-sales-price {
  color: #ec877b;
}
.te-popup.te-added-to-cart .te-popup-header,
.te-styled .te-popup.te-added-to-cart .te-popup-header {
  margin: 10px 0 20px 0;
  display: flex;
}
.te-popup.te-added-to-cart .te-popup-header h3,
.te-styled .te-popup.te-added-to-cart .te-popup-header h3 {
  color: #4f5058;
  font-size: 1em;
  margin: 0;
  line-height: 22px;
  font-weight: 400;
}
.te-popup.te-added-to-cart .te-popup-header .button-wrapper,
.te-styled .te-popup.te-added-to-cart .te-popup-header .button-wrapper {
  width: 1em;
  margin-left: auto;
}
.te-popup.te-added-to-cart .te-popup-header .button-wrapper .te-button-cross,
.te-styled .te-popup.te-added-to-cart .te-popup-header .button-wrapper .te-button-cross {
  top: -10px;
  right: 10px;
  position: relative;
}
.te-popup.te-added-to-cart .te-popup-img-container,
.te-styled .te-popup.te-added-to-cart .te-popup-img-container {
  text-align: center;
  margin-bottom: 1em;
}
.te-popup.te-added-to-cart .te-popup-img-container > img,
.te-styled .te-popup.te-added-to-cart .te-popup-img-container > img {
  max-width: 100%;
  max-height: 130px;
}
.te-popup.te-added-to-cart .te-popup-product-basic-info,
.te-styled .te-popup.te-added-to-cart .te-popup-product-basic-info {
  font-size: 0.8em;
  margin-bottom: 1em;
}
.te-popup.te-added-to-cart .te-popup-title,
.te-styled .te-popup.te-added-to-cart .te-popup-title {
  overflow: hidden;
  text-transform: none;
}
@media (max-width: 767px) {
  .te-popup.te-added-to-cart-header,
  .te-styled .te-popup.te-added-to-cart-header {
    margin-bottom: 1em;
  }
  .te-popup.te-added-to-cart-img-container,
  .te-styled .te-popup.te-added-to-cart-img-container {
    display: none;
  }
}
.te-popup.te-added-to-cart .te-button,
.te-styled .te-popup.te-added-to-cart .te-button {
  max-width: 370px;
  line-height: 0;
  padding: 10px;
}
.te-cart-list,
.te-styled .te-cart-list {
  padding: 0px 10px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: 380px;
}
.te-cart-list::-webkit-scrollbar,
.te-styled .te-cart-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.te-cart-list-wrapper,
.te-styled .te-cart-list-wrapper {
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #e7e7e7;
  width: 100%;
  max-width: 370px;
  z-index: 1;
}
.te-cart-list-wrapper.te-mobile,
.te-styled .te-cart-list-wrapper.te-mobile {
  width: 90%;
  margin: 0 auto;
}
.te-cart-list-header,
.te-styled .te-cart-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 20px;
}
.te-cart-list-header-text,
.te-styled .te-cart-list-header-text {
  color: #4f5058;
}
.te-cart-list-header-button,
.te-styled .te-cart-list-header-button {
  height: 15px;
  width: 15px;
  position: relative;
  display: flex;
  align-items: center;
}
.te-cart-list-header-button:hover,
.te-styled .te-cart-list-header-button:hover {
  cursor: pointer;
}
.te-cart-list-header-line,
.te-styled .te-cart-list-header-line {
  width: 100%;
  border-bottom: 2px solid #4f5058;
  border-radius: 3px;
}
.te-cart-list-empty,
.te-styled .te-cart-list-empty {
  padding: 20px 0 30px 0;
  font-size: 0.75em;
}
.te-cart-list-item,
.te-styled .te-cart-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  min-height: 100px;
  position: relative;
}
.te-cart-list-item-cross,
.te-styled .te-cart-list-item-cross {
  margin-right: -10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
}
.te-cart-list-item-cross .te-button-cross,
.te-styled .te-cart-list-item-cross .te-button-cross {
  width: 10px;
  height: 10px;
  position: absolute;
}
.te-cart-list-item-cross:hover,
.te-styled .te-cart-list-item-cross:hover {
  cursor: pointer;
}
.te-cart-list-item img,
.te-styled .te-cart-list-item img {
  max-width: 100px;
  max-height: 130px;
}
.te-cart-list-item-image,
.te-styled .te-cart-list-item-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-cart-list-item-info,
.te-styled .te-cart-list-item-info {
  color: #4f5058;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
  line-height: 16px;
  margin-left: 18px;
  padding: 0 0 4px 0;
  width: 100%;
}
.te-cart-list-item-info-brand,
.te-styled .te-cart-list-item-info-brand {
  color: #a3a0a0;
  font-weight: 500;
  max-width: 200px;
}
.te-cart-list-item-info-name,
.te-styled .te-cart-list-item-info-name {
  line-height: 16px;
  font-weight: 500;
  max-width: 200px;
}
.te-cart-list-item-info-price-discount,
.te-styled .te-cart-list-item-info-price-discount {
  display: flex;
}
.te-cart-list-item-info-price-no-sales,
.te-styled .te-cart-list-item-info-price-no-sales {
  text-decoration: line-through;
}
.te-cart-list-item-info-price-sales,
.te-styled .te-cart-list-item-info-price-sales {
  margin-left: 10px;
  color: #ec877b;
  font-weight: 500;
}
.te-cart-list-item-info-attribute,
.te-styled .te-cart-list-item-info-attribute {
  margin-bottom: 8px;
}
.te-cart-list-item-info-total,
.te-styled .te-cart-list-item-info-total {
  display: flex;
  justify-content: space-between;
}
.te-cart-list-item-info-total-qty,
.te-styled .te-cart-list-item-info-total-qty {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}
.te-cart-list-item-info-total-qty-value,
.te-styled .te-cart-list-item-info-total-qty-value {
  margin-left: 12px;
}
.te-cart-summary,
.te-styled .te-cart-summary {
  font-size: 12px;
  padding: 0 10px;
}
.te-cart-summary .te-label,
.te-styled .te-cart-summary .te-label {
  text-transform: uppercase;
}
.te-cart-summary-total,
.te-styled .te-cart-summary-total {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
.te-cart-summary-total-bold,
.te-styled .te-cart-summary-total-bold {
  font-weight: 500;
  text-transform: uppercase;
}
.te-cart-summary-total-price,
.te-styled .te-cart-summary-total-price {
  font-weight: 500;
}
.te-cart-summary-total-vat,
.te-styled .te-cart-summary-total-vat {
  text-transform: lowercase;
  font-weight: 500;
}
.te-cart-summary-total-incl,
.te-styled .te-cart-summary-total-incl {
  font-weight: 400;
}
.te-cart-summary-total-est,
.te-styled .te-cart-summary-total-est {
  text-transform: uppercase;
  font-weight: 500;
}
.te-cart-checkout-button,
.te-styled .te-cart-checkout-button {
  font-size: 14px;
  margin-top: 20px;
}
.te-cart-checkout-button .te-button,
.te-styled .te-cart-checkout-button .te-button {
  width: 100%;
}
.te-separate-line,
.te-styled .te-separate-line {
  border-bottom: 1px solid #e7e7e7;
  width: 120%;
  transform: translateX(-20px);
}
.te-popup-wrapper.te-mobile,
.te-styled .te-popup-wrapper.te-mobile {
  width: 100%;
}
