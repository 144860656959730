.te-dropdown-container {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .te-dropdown {
    margin: 0 0 20px;
    max-width: 250px;
  }
}
.te-dropdown div {
  outline: none;
  box-shadow: none;
  border-color: #333;
}
.te-dropdown-select {
  border: 1px solid #333;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.8em;
  position: relative;
  text-align-last: center;
}
.te-dropdown-select > svg {
  position: absolute;
  width: 1.8em;
  right: 0.5em;
  pointer-events: none;
  top: 55%;
  transform: translateY(-50%);
}
.te-dropdown-select select {
  padding: 13px 10px;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background: transparent;
}
.te-dropdown-select select:active,
.te-dropdown-select select:hover,
.te-dropdown-select select:focus {
  outline: 0;
}
