.te-modular-image {
  align-items: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: white;
  box-sizing: border-box;
  display: -webkit-box;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 650px;
}
.te-modular-image figure {
  margin: 0;
  padding: 0;
}
.te-modular-image .te-image {
  display: block;
  position: relative;
  overflow: visible;
  width: 100%;
  max-height: 100%;
}
.te-modular-image .te-slider-iiz {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.te-modular-image .te-slider-iiz.te-mobile img:first-child,
.te-modular-image .te-slider-iiz.te-tablet img:first-child,
.te-modular-image .te-slider-iiz.te-desktop img:first-child {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  margin: 0 auto;
  object-fit: contain;
}
.te-modular-image .te-slider-iiz .iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  margin: 0 auto;
  object-fit: contain;
  max-height: 650px;
}
.te-modular-image .te-slider-iiz .iiz__zoom-img {
  width: auto !important;
  max-width: 200vw !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}
.te-modular-image .te-slider-iiz .iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
  background-color: white;
}
.te-modular-image .te-slider-iiz .iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
.te-modular-image .te-slider-iiz .iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
}
.te-modular-image .te-slider-iiz .iiz__btn:before {
  content: ' ';
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}
.te-modular-image .te-slider-iiz .iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
  display: none;
}
.te-modular-image .te-slider-iiz .iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}
.te-modular-image .te-slider-iiz .iiz__close--visible {
  visibility: visible;
  opacity: 1;
}
.te-modular-image .te-slider-iiz .iiz__close::before {
  content: ' ';
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}
.te-modular-image .te-slider {
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.te-modular-image .te-slider-dots {
  z-index: 999;
  position: absolute;
  width: 120px;
  overflow: hidden;
  height: 20px;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
}
.te-modular-image .te-slider-dots-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  transition: transform 0.5s ease;
}
.te-modular-image .te-slider-dots-single {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.te-modular-image .te-slider-dots-single-dot {
  box-shadow: 1px 1px 2px 0.3px rgba(0, 0, 0, 0.77);
  border: none;
  font-size: 0;
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  padding: 0;
}
.te-modular-image .te-slider-dots-single-dot.transition {
  transition: all 0.1s linear;
}
.te-modular-image .te-slider-dots-single-dot.size0 {
  width: 13px;
  height: 13px;
  opacity: 1;
}
.te-modular-image .te-slider-dots-single-dot.size1 {
  width: 11px;
  height: 11px;
  opacity: 0.5;
}
.te-modular-image .te-slider-dots-single-dot.size2 {
  width: 9px;
  height: 9px;
  opacity: 0.5;
}
.te-modular-image .te-slider-dots-single-dot.size3 {
  width: 7px;
  height: 7px;
  opacity: 0.5;
}
.te-modular-image .te-slider-dots-single-dot.size4 {
  width: 4px;
  height: 4px;
  opacity: 0.5;
}
.te-modular-image .te-slider-dots-single-dot.size5 {
  width: 2px;
  height: 2px;
  opacity: 0.5;
}
.te-modular-image .te-slider .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.te-modular-image .te-slider .slick-list .slick-track {
  position: relative;
  left: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  max-height: 650px;
}
.te-modular-image .te-slider .slick-list .slick-track .slick-slide {
  display: block;
  text-align: center;
  box-sizing: border-box;
}
.te-modular-image .te-slider .slick-list .slick-track .slick-slide,
.te-modular-image .te-slider .slick-list .slick-track .slick-slide * {
  outline: none !important;
}
.te-modular-image .te-slider .te-product-image-arrow {
  opacity: 1;
}
.te-modular-image .te-slider .te-product-image-arrow.slick-prev {
  transform: translate(-50%, -50%);
}
.te-modular-image .te-slider .te-product-image-arrow.slick-next {
  right: 0;
  left: unset;
  transform: translate(-25%, -50%);
}
.te-modular-image .te-thumb .te-img::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 999999;
}
.te-modular-image .te-thumb.active {
  opacity: 0.5;
}
.te-modular-image .te-arrow.te-desktop.slick-next,
.te-modular-image .te-arrow.te-desktop.slick-prev {
  transform: translateX(-50%) rotate(90deg);
  opacity: 1;
  height: 50px;
  width: 50px;
  left: 50%;
}
.te-modular-image .te-arrow.te-tablet.slick-next,
.te-modular-image .te-arrow.te-tablet.slick-prev {
  opacity: 1;
}
.te-modular-image .te-arrow.te-desktop.slick-next {
  top: 90%;
}
.te-modular-image .te-arrow.te-desktop.slick-prev {
  top: 0;
}
