.te-loader-checkout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 14.6rem;
  margin-top: 7.3rem;
  margin-bottom: 7.3rem;
}
.te-loader-checkout:before,
.te-loader-checkout:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.te-loader-checkout:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 0.4rem #303248;
  animation-name: pulsA;
}
.te-loader-checkout:after {
  width: calc(100% - 0.4rem*2);
  padding-bottom: calc(100% - 0.4rem*2);
  box-shadow: 0 0 0 0 #303248;
  animation-name: pulsB;
}
@keyframes pulsA {
  0% {
    box-shadow: inset 0 0 0 0.4rem #303248;
    opacity: 1;
  }
  50%,
  100% {
    box-shadow: inset 0 0 0 0 #303248;
    opacity: 0;
  }
}
@keyframes pulsB {
  0%,
  50% {
    box-shadow: 0 0 0 0 #303248;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.4rem #303248;
    opacity: 1;
  }
}
