.te-checkout-diffs-dialog,
.te-styled .te-checkout-diffs-dialog {
  padding: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
}
.te-checkout-diffs-dialog.te-inDialog,
.te-styled .te-checkout-diffs-dialog.te-inDialog {
  margin: 10px;
  max-width: 1350px;
}
.te-checkout-diffs-dialog.te-inDialog.te-desktop,
.te-styled .te-checkout-diffs-dialog.te-inDialog.te-desktop {
  margin: 10px auto;
}
.te-checkout-diffs-dialog-container,
.te-styled .te-checkout-diffs-dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.5s linear 1;
}
.te-checkout-diffs-dialog-text,
.te-styled .te-checkout-diffs-dialog-text {
  margin-bottom: 50px;
  color: #4f5058;
}
.te-checkout-diffs-dialog-button,
.te-styled .te-checkout-diffs-dialog-button {
  width: 240px;
  font-size: 14px;
  display: block;
  background-color: #2c2c2c;
  border: 1px solid #2c2c2c;
  padding: 10px 27px;
  border-radius: 5px;
  color: white;
  transition: all 0.5s ease;
  text-transform: uppercase;
}
.te-checkout-diffs-dialog-button:hover,
.te-styled .te-checkout-diffs-dialog-button:hover {
  cursor: pointer;
  color: #2c2c2c;
  background-color: white;
}
