.te-container {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
@media (max-width: 479px) {
  .te-container {
    padding: 10px;
  }
}
@media (min-width: 480px) and (max-width: 959px) {
  .te-container {
    padding: 15px;
  }
}
@media (min-width: 960px) {
  .te-container {
    padding: 25px;
  }
}
.te-container.te-container-horizontal {
  flex-flow: row nowrap;
}
@media (max-width: 479px) {
  .te-container.te-container-horizontal {
    flex-direction: column;
  }
}
.te-container.te-container-vertical {
  flex-flow: column nowrap;
}
.te-container .te-container-item {
  flex: 1;
  margin: 0 10px;
  width: 100%;
}
