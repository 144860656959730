.fade-in {
  -webkit-animation: fade-in 0.25s ease-in;
  -moz-animation: fade-in 0.25s ease-in;
  animation: fade-in 0.25s ease-in;
  animation-fill-mode: forwards;
}
.delayed-fade-in {
  -webkit-animation: fade-in 2s ease-in;
  -moz-animation: fade-in 2s ease-in;
  animation: fade-in 2s ease-in;
  animation-fill-mode: forwards;
  animation-fill-mode: both;
  animation-delay: 1s;
}
.fade-out {
  -webkit-animation: fade-out 1s ease-out;
  -moz-animation: fade-out 1s ease-out;
  animation: fade-out 1s ease-out;
  animation-fill-mode: forwards;
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
