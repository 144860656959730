.te-modular-product-container {
  box-sizing: border-box;
  max-width: 1350px;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.te-product-container.te-tablet,
.te-styled .te-product-page .te-product-container.te-tablet {
  display: flex;
  padding: 0 20px;
}
.te-product-container.te-desktop,
.te-styled .te-product-page .te-product-container.te-desktop {
  margin: 0 auto;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  height: 660px;
  display: flex;
  padding: 0 20px;
}
.te-product-container .te-delivery,
.te-styled .te-product-page .te-product-container .te-delivery {
  margin: 1em 0;
}
.te-product-container-details,
.te-styled .te-product-page .te-product-container-details {
  word-break: break-word;
}
.te-product-container-details .te-details-brand,
.te-styled .te-product-page .te-product-container-details .te-details-brand {
  color: #a3a0a0;
  font-weight: 500;
  text-transform: uppercase;
}
.te-product-container-details .te-details-brand.te-mobile,
.te-styled .te-product-page .te-product-container-details .te-details-brand.te-mobile {
  align-self: start;
  font-size: 0.875em;
  padding-right: 20px;
}
.te-product-container-details-name,
.te-styled .te-product-page .te-product-container-details-name {
  color: #4f5058;
  font-weight: 500;
  text-transform: uppercase;
}
.te-product-container-details-name.te-mobile,
.te-styled .te-product-page .te-product-container-details-name.te-mobile {
  padding-right: 20px;
  font-size: 1em;
}
.te-product-container-details.te-mobile,
.te-styled .te-product-page .te-product-container-details.te-mobile {
  padding: 0 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.te-product-container-details.te-mobile .te-delivery,
.te-styled .te-product-page .te-product-container-details.te-mobile .te-delivery {
  margin-left: 0;
}
.te-product-container-details.te-tablet,
.te-styled .te-product-page .te-product-container-details.te-tablet {
  width: 50%;
  padding-left: 15px;
}
.te-product-container-details.te-desktop,
.te-styled .te-product-page .te-product-container-details.te-desktop {
  width: calc(40% - 50px);
  flex-basis: calc(40% - 50px);
  margin-right: 50px;
  margin-left: 30px;
}
.te-product-container-details-basic,
.te-styled .te-product-page .te-product-container-details-basic {
  font-weight: 500;
  text-align: left;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
}
.te-product-container-details-basic.te-mobile,
.te-styled .te-product-page .te-product-container-details-basic.te-mobile {
  display: grid;
  grid-template-areas: 'productBrand' 'productName' 'productPrice';
  margin-bottom: 20px;
}
.te-product-container-warning-mescsage,
.te-styled .te-product-page .te-product-container-warning-mescsage {
  color: #ec877b;
  padding-bottom: 10px;
}
.te-product-container .te-description-link,
.te-styled .te-product-page .te-product-container .te-description-link {
  padding-bottom: 17px;
}
.te-product-container .te-description-link.te-mobile,
.te-styled .te-product-page .te-product-container .te-description-link.te-mobile {
  display: none;
}
.te-product-container .te-description-link:hover,
.te-styled .te-product-page .te-product-container .te-description-link:hover {
  cursor: pointer;
}
.te-product-container .te-description-link-title,
.te-styled .te-product-page .te-product-container .te-description-link-title {
  font-weight: 400;
  color: #303248;
  text-transform: capitalize;
  text-align: left;
  display: block;
  font-size: 12px;
  padding: 10px 10px 10px 0;
  position: relative;
  text-decoration: underline;
  margin-top: 20px;
}
.te-product-container .te-details,
.te-styled .te-product-page .te-product-container .te-details {
  margin-bottom: 3px;
}
.te-product-container .te-basic-price-main,
.te-styled .te-product-page .te-product-container .te-basic-price-main {
  margin-bottom: 10px;
}
.te-product-container .te-basic-price-main-sales,
.te-styled .te-product-page .te-product-container .te-basic-price-main-sales {
  margin-left: 20px;
}
.te-product-container-units,
.te-styled .te-product-page .te-product-container-units {
  color: #a3a0a0;
  font-size: 0.75em;
  margin-top: 5px;
  order: 4;
}
.te-product-container .te-modular-dropdown,
.te-styled .te-product-page .te-product-container .te-modular-dropdown {
  margin: 0;
}
.te-product-container .te-modular-dropdown.te-tablet,
.te-styled .te-product-page .te-product-container .te-modular-dropdown.te-tablet,
.te-product-container .te-modular-dropdown.te-desktop,
.te-styled .te-product-page .te-product-container .te-modular-dropdown.te-desktop {
  margin: 30px 0;
}
.te-product-container .te-color-relations,
.te-styled .te-product-page .te-product-container .te-color-relations {
  margin: 20px 0;
}
.te-product-container .te-details-name,
.te-styled .te-product-page .te-product-container .te-details-name,
.te-product-container .te-details-brand,
.te-styled .te-product-page .te-product-container .te-details-brand {
  text-transform: uppercase;
}
.te-product-container .te-slider-iiz,
.te-styled .te-product-page .te-product-container .te-slider-iiz {
  max-height: 660px;
}
@keyframes fadeIn {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loadingAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
