.te-cart {
  display: block;
  width: 45px;
  height: auto;
  position: relative;
  outline: none;
  z-index: 1;
}
.te-cart .te-button-clear {
  width: 100%;
  height: 100%;
}
.te-cart .te-button-clear svg {
  pointer-events: none;
  width: 90%;
}
.te-cart:hover .tipser-cart-size-indicator {
  transform: translateY(-2px) scale(1.03);
}
.te-cart-size-indicator {
  align-items: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  background: black;
  color: white;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 1px;
  width: 30%;
  height: 30%;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  text-shadow: 0 0 1px #0003;
  letter-spacing: -0.5px;
  box-shadow: 0.5px 0.5px 1px #0005;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}
.te-cart-size-indicator.te-cart-size-indicator_animated {
  animation-name: elevator;
  animation-duration: 1s;
}
.te-cart-size-indicator.te-cart-size-indicator_infinity {
  text-indent: -99px;
}
.te-cart-size-indicator.te-cart-size-indicator_infinity:after {
  content: '+';
  display: block;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -2px;
}
/********
* pulse *
*********/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/******/
/* Move indicator up and down and do a tiny heartbeat */
@keyframes elevator {
  0% {
    transform: translateY(0px);
    box-shadow: 0 0 0 0 #0000;
  }
  40% {
    transform: translateY(-6px);
    box-shadow: 0 0 0 1px #0005;
  }
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0 0 0 8px #0000;
  }
}
