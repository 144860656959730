.te-checkout-stock-changed,
.te-styled .te-checkout-stock-changed {
  padding: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
}
.te-checkout-stock-changed.te-inDialog,
.te-styled .te-checkout-stock-changed.te-inDialog {
  margin: 10px;
  max-width: 1350px;
}
.te-checkout-stock-changed.te-inDialog.te-desktop,
.te-styled .te-checkout-stock-changed.te-inDialog.te-desktop {
  margin: 10px auto;
}
.te-checkout-stock-changed-container,
.te-styled .te-checkout-stock-changed-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeIn 0.5s linear 1;
}
.te-checkout-stock-changed-text,
.te-styled .te-checkout-stock-changed-text {
  margin-bottom: 50px;
  color: #4f5058;
}
.te-checkout-stock-changed-button,
.te-styled .te-checkout-stock-changed-button {
  width: 240px;
  font-size: 14px;
  display: block;
  background-color: #2c2c2c;
  border: 1px solid #2c2c2c;
  padding: 10px 27px;
  border-radius: 5px;
  color: white;
  transition: all 0.5s ease;
  text-transform: uppercase;
}
.te-checkout-stock-changed-button:hover,
.te-styled .te-checkout-stock-changed-button:hover {
  cursor: pointer;
  color: #2c2c2c;
  background-color: white;
}
