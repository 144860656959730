.te-product-container {
  width: 100%;
  position: relative;
}
.te-product-container .te-product-image {
  box-sizing: border-box;
  position: relative;
  margin: 0;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  align-items: center;
}
.te-product-container .te-product-image-wrapper {
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  display: flex;
}
.te-product-container .te-product-image-wrapper .te-img {
  max-height: 300px;
  margin: 0 auto;
}
.te-product-container .te-product-image-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.te-product-container .te-product-thumbnails {
  display: block;
  position: relative;
  overflow: visible;
  width: 100%;
}
.te-product-container .te-product-thumbnails .te-product-thumb {
  cursor: pointer;
  max-width: 90px;
  max-height: 90px;
  min-width: 50px;
  width: 90px;
  outline: none;
  position: relative;
  text-align: center;
}
.te-product-container .te-product-thumbnails .te-product-thumb .te-img {
  max-width: 100%;
  max-height: 100%;
  width: 90%;
  margin: 0 auto;
  min-height: 80px;
  width: 88px;
  height: 88px;
}
.te-product-container .te-product-thumbnails .te-product-thumb .te-img img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid lightgray;
  width: 90%;
  margin: 0 auto;
  min-height: 80px;
  width: 88px;
  height: 88px;
}
.te-product-container .te-product-thumbnails .slick-slider {
  margin-top: 5px;
}
.te-product-container .te-product-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  max-width: 100%;
  letter-spacing: 1px;
  padding: 0 15px;
  position: relative;
}
.te-product-container .te-product-details .te-product-basic-info {
  margin-bottom: 26px;
  text-align: left;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-name {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-brand {
  font-size: 1em;
  text-transform: uppercase;
  margin: 8px 0;
  font-weight: 500;
  letter-spacing: 2px;
  color: #a3a0a0;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-price-container {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-price-container .te-product-price-main .te-product-sales-price {
  color: #cd5550;
  font-weight: 700;
  font-size: 1.9em;
  letter-spacing: 0;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-price-container .te-product-price-main .te-product-regular-price {
  color: #5f5f5f;
  text-decoration: line-through;
  font-size: 1.1em;
}
.te-product-container .te-product-details .te-product-basic-info .te-product-price-container .te-product-price-vat-info {
  color: #a6aaa9;
  font-size: 0.8em;
  font-weight: 100;
  margin-top: 0.3em;
  margin-left: 5px;
  text-indent: 2px;
}
.te-product-container .te-product-details .te-button {
  cursor: pointer;
  margin: 10px auto;
  width: 100%;
  font-size: 12px;
}
.te-product-container .te-product-details .te-button.disabled {
  cursor: not-allowed;
}
.te-product-container .te-product-details .te-button.disabled .te-button-body {
  background-color: #dddddd;
}
.te-product-container .te-product-details .te-button.disabled .te-button-text {
  color: #525860;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp {
  list-style-type: none;
  padding: 0;
  display: block;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item {
  display: flex;
  letter-spacing: 0.08em;
  line-height: 1.6;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item .te-product-icon {
  margin-right: 5px;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item .te-product-icon svg {
  width: 20px;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item .te-product-info {
  display: flex;
  font-size: 0.8em;
  letter-spacing: 0;
  color: #333;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item .te-product-info-label {
  margin-right: 5px;
  font-weight: 800;
  color: #000;
}
.te-product-container .te-product-details .te-product-info-container .te-product-usp-item .te-product-info-label:first-letter {
  text-transform: capitalize;
}
.te-product-container .te-product-details .te-product-description {
  cursor: pointer;
  border-top: 1px solid #bdc3c7;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .te-product-container .te-product-details .te-product-description {
    padding-bottom: 17px;
  }
}
.te-product-container .te-product-details .te-product-description-title {
  text-transform: capitalize;
  text-align: left;
  display: block;
  font-size: 0.85em;
  padding: 10px 10px 10px 0;
  position: relative;
  font-weight: bold;
}
.te-product-container .te-product-details .te-product-description-title svg {
  width: 10px;
}
.te-product-container .te-product-details .te-product-description-content {
  font-size: 0.8em;
  letter-spacing: 0;
  text-align: left;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.te-product-container .te-product-details .te-product-description-content.active {
  max-height: 200px;
  margin: 0;
}
.te-product-container .te-product-details .te-product-description-content.more {
  transition: max-height 0.2s ease-in;
  max-height: 1000px;
}
.te-product-container .te-product-details .te-product-description-content p:first-child {
  font-size: inherit;
  margin: 0;
}
.te-product-container .te-product-details .te-product-description-content p {
  line-height: 1.7;
  margin: 0;
  font-size: inherit;
}
.te-product-container .te-product-details .te-product-description-more {
  margin: 5px auto 0 auto;
  display: block;
  text-align: center;
  width: 50%;
  border: 2px solid lightgray;
  font-size: 12px;
  width: 90px;
}
.te-product-container .slick-slide {
  display: inline-block;
  text-align: center;
}
.te-product.te-single-product__column {
  width: 80%;
  margin: 0 auto;
}
.te-product.te-single-product__column .te-product-image {
  flex-direction: row;
  max-height: 500px;
  justify-content: center;
  align-items: unset;
}
.te-product.te-single-product__column .te-product-image .te-product-thumbnails {
  width: 90px;
}
.te-product.te-single-product__column .te-product-details {
  width: 100%;
  margin: 30px auto 0 auto;
}
.te-product-full {
  max-width: 1000px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
}
.te-product-full figure {
  margin: 0;
}
.te-product-full .te-product-image {
  align-items: unset;
  flex-direction: row;
  height: 100%;
  max-height: 500px;
  padding: 0;
  width: 60%;
}
.te-product-full .te-product-thumbnails {
  width: 90px;
}
.te-product-full .te-product-details {
  margin-left: 10px;
}
.te-single-product__column .te-product-image-wrapper,
.te-product-full .te-product-image-wrapper {
  max-height: 500px;
}
.te-single-product__column .te-product-image-wrapper .te-img,
.te-product-full .te-product-image-wrapper .te-img {
  max-height: 100%;
  min-height: 100%;
}
.te-single-product__column .te-product-thumbnails .te-product-thumb,
.te-product-full .te-product-thumbnails .te-product-thumb {
  min-width: 90px;
}
.te-single-product__column .slick-prev,
.te-product-full .slick-prev {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
}
.te-single-product__column .slick-next,
.te-product-full .slick-next {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 100%;
}
.te-product-full-size {
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  z-index: 99999;
  display: none;
}
