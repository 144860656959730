.te-hero-brand {
  height: auto;
  background-color: #f5f5f5;
  padding-bottom: 25px;
}
.te-hero-brand .te-hero-brand-img {
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.te-hero-brand .te-hero-brand-name,
.te-hero-brand .te-hero-brand-title {
  text-align: center;
}
.te-hero-brand .te-hero-brand-name {
  text-transform: uppercase;
  margin-top: 45px;
}
.te-brand {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}
.te-brand-logo-intro {
  display: flex;
  flex-wrap: wrap;
}
.te-brand-logo {
  width: 100%;
  min-height: 150px;
  position: relative;
  padding: 0 20px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .te-brand-logo {
    width: 50%;
    min-height: 240px;
    padding: 0;
    margin: 60px 0 40px;
  }
}
.te-brand-logo img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.te-brand-intro-text {
  width: 100%;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .te-brand-intro-text {
    width: 50%;
    padding: 50px 20px 50px 40px;
  }
}
.te-brand-bio {
  padding: 20px 40px 70px;
  font-size: 18px;
  line-height: 1.7em;
}
