.te-color-relations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  height: 120px;
}
.te-color-relations-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  color: #303248;
}
.te-color-relations-tile {
  margin: 4px;
}
.te-color-relations-image {
  max-width: 100%;
  max-height: 95px;
  display: block;
}
.te-color-relations-image:hover {
  cursor: pointer;
}
.te-color-relations-image.active {
  border-bottom: 3px solid #4f5058;
}
.te-color-relations-products .slick-track {
  margin-left: 0;
}
.te-color-relations-arrow {
  z-index: 1;
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: #000;
  padding: 0;
  border: none;
  outline: none;
  opacity: 1;
}
.te-color-relations-arrow.slick-next {
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
}
.te-color-relations-arrow.slick-prev {
  left: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.te-color-relations-arrow {
  z-index: 1;
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: #000;
  padding: 0;
  border: none;
  outline: none;
  opacity: 1;
}
.te-color-relations-arrow.slick-next {
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
}
.te-color-relations-arrow.slick-prev {
  left: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
}
