.te-checkout-confirmation,
.te-styled .te-checkout-confirmation {
  color: #4f5058;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.te-checkout-confirmation-heading,
.te-styled .te-checkout-confirmation-heading {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
.te-checkout-confirmation-heading.desktop,
.te-styled .te-checkout-confirmation-heading.desktop {
  font-size: 28px;
  margin-bottom: 50px;
}
.te-checkout-confirmation-animation,
.te-styled .te-checkout-confirmation-animation {
  width: 95px;
  height: 95px;
  margin: 30px auto;
}
.te-checkout-confirmation-animation.desktop,
.te-styled .te-checkout-confirmation-animation.desktop {
  width: 120px;
  height: 120px;
  margin: 50px auto;
}
.te-checkout-confirmation-order-number,
.te-styled .te-checkout-confirmation-order-number {
  font-size: 14px;
  max-width: 630px;
  margin: 0 auto 50px auto;
}
.te-checkout-confirmation-order-number span,
.te-styled .te-checkout-confirmation-order-number span {
  font-weight: 500;
}
.te-checkout-confirmation-order-number.desktop,
.te-styled .te-checkout-confirmation-order-number.desktop {
  margin: 0 auto 80px auto;
  line-height: 20px;
}
.te-checkout-confirmation-address,
.te-styled .te-checkout-confirmation-address {
  width: 100%;
  max-width: 630px;
  margin-bottom: 70px;
}
.te-checkout-confirmation-address-heading,
.te-styled .te-checkout-confirmation-address-heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.te-checkout-confirmation-address-heading:first-letter,
.te-styled .te-checkout-confirmation-address-heading:first-letter {
  text-transform: uppercase;
}
.te-checkout-confirmation-address-heading.desktop,
.te-styled .te-checkout-confirmation-address-heading.desktop {
  font-size: 20px;
  margin-bottom: 50px;
}
.te-checkout-confirmation-address-columns,
.te-styled .te-checkout-confirmation-address-columns {
  display: flex;
  flex-direction: column;
}
.te-checkout-confirmation-address-columns.desktop,
.te-styled .te-checkout-confirmation-address-columns.desktop {
  flex-direction: row;
  justify-content: space-between;
}
.te-checkout-confirmation-address-row,
.te-styled .te-checkout-confirmation-address-row {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 20px;
}
.te-checkout-confirmation-address-row.desktop,
.te-styled .te-checkout-confirmation-address-row.desktop {
  margin-bottom: 25px;
}
.te-checkout-confirmation-address-row-heading,
.te-styled .te-checkout-confirmation-address-row-heading {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;
}
.te-checkout-confirmation-address-row-heading.desktop,
.te-styled .te-checkout-confirmation-address-row-heading.desktop {
  font-size: 16px;
  line-height: 22px;
}
.te-checkout-confirmation-links,
.te-styled .te-checkout-confirmation-links {
  margin: 50px auto 70px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.te-checkout-confirmation-links.desktop,
.te-styled .te-checkout-confirmation-links.desktop {
  margin: 60px auto;
  max-width: 480px;
  padding: 0;
}
.te-checkout-confirmation-link,
.te-styled .te-checkout-confirmation-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.te-checkout-confirmation-anchor,
.te-styled .te-checkout-confirmation-anchor {
  color: #4f5058;
}
.te-checkout-confirmation-anchor:hover,
.te-styled .te-checkout-confirmation-anchor:hover {
  cursor: pointer;
}
.te-checkout-confirmation-link-icon,
.te-styled .te-checkout-confirmation-link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #f2f3f7;
  border-radius: 35px;
  margin-bottom: 10px;
}
.te-checkout-confirmation-link-icon.desktop,
.te-styled .te-checkout-confirmation-link-icon.desktop {
  width: 60px;
  height: 60px;
}
.te-checkout-confirmation-link-text,
.te-styled .te-checkout-confirmation-link-text {
  position: absolute;
  font-size: 12px;
  top: 48px;
  white-space: normal;
  text-align: center;
}
.te-checkout-confirmation-link-text.desktop,
.te-styled .te-checkout-confirmation-link-text.desktop {
  font-size: 14px;
  white-space: nowrap;
  top: 69px;
}
.te-checkout-confirmation-bottom-text,
.te-styled .te-checkout-confirmation-bottom-text {
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  margin-bottom: 50px;
}
.te-checkout-confirmation-bottom-text.desktop,
.te-styled .te-checkout-confirmation-bottom-text.desktop {
  font-size: 14px;
  max-width: 630px;
  margin: 0 auto 80px auto;
  line-height: 20px;
}
.te-checkout-confirmation-container,
.te-styled .te-checkout-confirmation-container {
  margin: 0 auto;
  max-width: 700px;
}
.te-checkout-confirmation-container .te-checkout-container,
.te-styled .te-checkout-confirmation-container .te-checkout-container,
.te-checkout-confirmation-container .te-checkout-container.desktop,
.te-styled .te-checkout-confirmation-container .te-checkout-container.desktop {
  padding: 0;
}
.te-checkout .te-dialog-inner .te-checkout,
.te-styled .te-checkout .te-dialog-inner .te-checkout {
  height: 100%;
}
