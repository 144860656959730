.te-colletion .iiz,
.te-product .iiz,
.te-dialog-product .iiz {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
  display: flex;
  align-items: center;
  width: 100%;
}
.te-colletion .iiz__img,
.te-product .iiz__img,
.te-dialog-product .iiz__img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  margin: 0 auto;
  object-fit: contain;
}
.te-colletion .iiz__zoom-img,
.te-product .iiz__zoom-img,
.te-dialog-product .iiz__zoom-img {
  width: auto !important;
  max-width: 200vw !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}
.te-colletion .iiz__zoom-img--visible,
.te-product .iiz__zoom-img--visible,
.te-dialog-product .iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
  background-color: white;
}
.te-colletion .iiz__zoom-portal,
.te-product .iiz__zoom-portal,
.te-dialog-product .iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
.te-colletion .iiz__btn,
.te-product .iiz__btn,
.te-dialog-product .iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
}
.te-colletion .iiz__btn:before,
.te-product .iiz__btn:before,
.te-dialog-product .iiz__btn:before {
  content: ' ';
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}
.te-colletion .iiz__hint,
.te-product .iiz__hint,
.te-dialog-product .iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
  display: none;
}
.te-colletion .iiz__close,
.te-product .iiz__close,
.te-dialog-product .iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}
.te-colletion .iiz__close--visible,
.te-product .iiz__close--visible,
.te-dialog-product .iiz__close--visible {
  visibility: visible;
  opacity: 1;
}
.te-colletion .iiz__close::before,
.te-product .iiz__close::before,
.te-dialog-product .iiz__close::before {
  content: ' ';
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}
.te-dialog-product-slider-iiz.te-desktop.iiz {
  margin: 0 25px;
}
