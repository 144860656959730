.te-preowned-label,
.te-styled .te-preowned-label {
  background-color: #755841;
  color: #ffffff;
  font-size: 0.7em;
  line-height: 2em;
  padding: 0 10px;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
