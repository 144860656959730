.te-basic-price {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
}
.te-basic-price.te-mobile {
  margin-top: 0;
}
.te-basic-price-main {
  font-weight: 300;
  font-size: 1.25em;
  display: flex;
  align-items: center;
}
.te-basic-price-main.te-mobile {
  font-size: 0.875em;
}
.te-basic-price-main.te-mobile .te-basic-price-main-sales,
.te-basic-price-main.te-mobile .te-basic-price-main-regular {
  margin: 0 10px 0 0;
}
.te-basic-price-main.te-mobile .te-basic-price-main-vat {
  margin: 0;
}
.te-basic-price-main-sales {
  color: #ec877b;
  margin-left: 10px;
  letter-spacing: 0;
}
.te-basic-price-main-regular {
  color: #4f5058;
  text-decoration: line-through;
}
.te-basic-price-main-regular.te-mobile {
  margin-right: 0;
}
.te-basic-price-main-vat {
  color: #a3a0a0;
  font-size: 0.75em;
  font-weight: 400;
  margin-top: 0.3em;
  margin-left: 5px;
  text-indent: 2px;
}
.te-basic-price-main-vat.te-mobile {
  font-size: 0.6875em;
}
.te-basic-price-main-discount {
  margin-top: 5px;
  font-size: 1.25em;
  color: #ec877b;
  font-weight: 300;
  text-transform: uppercase;
}
.te-basic-price-main-discount.te-mobile {
  font-size: 0.875em;
}
.te-basic-price-main-units {
  color: #a3a0a0;
  font-size: 0.75em;
  margin-top: 5px;
}
