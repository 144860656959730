.te-inline-brand-content {
  max-width: 960px;
  margin: 20px auto;
  background-image: radial-gradient(#fff, #fbfbfb, #fff);
}
.te-inline-brand .te-profile {
  z-index: 0;
}
.te-inline-brand .te-brand-content-logo {
  width: 200px;
  height: auto;
}
.te-inline-brand .te-brand-content {
  position: relative;
  min-width: 300px;
  width: 100%;
  color: #fff;
  text-align: right;
  line-height: 1.4em;
  background-color: transparent;
  height: 200px;
  z-index: 3;
  max-width: 400px;
  padding: 0 20px;
}
.te-inline-brand .te-brand-content img {
  position: absolute;
  right: 0%;
  top: 50%;
  opacity: 1;
  width: 100%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.te-inline-brand .te-brand-content figcaption {
  position: relative;
  max-width: 100%;
  width: auto;
  text-align: center;
}
.te-inline-brand .te-brand-content figcaption p {
  color: #fff;
  font-size: 0.8em;
}
.te-inline-brand .te-brand-content-intro {
  margin: 0;
  width: 100%;
  opacity: 1;
}
.te-inline-brand .te-brand-content-logo {
  z-index: 1;
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.te-inline-brand .te-brand-bio {
  margin: 20px;
}
.te-inline-brand .te-brand-bio p {
  font-size: 0.8em;
}
.te-inline-brand .te-brand-products {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background: inherit;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.te-inline-brand .te-brand-products .te-product-card {
  width: 50%;
}
@media (min-width: 768px) {
  .te-inline-brand .te-brand-products .te-product-card {
    width: 33%;
  }
}
@media (min-width: 960px) {
  .te-inline-brand .te-brand-products .te-product-card {
    width: 25%;
  }
}
.te-inline-brand .te-brand-subtitle {
  padding: 30px;
}
@media (min-width: 960px) {
  .te-inline-brand * {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
  }
  .te-inline-brand .te-brand-content {
    max-width: 400px;
    float: left;
  }
  .te-inline-brand .te-brand-content:hover img {
    right: -50%;
  }
  .te-inline-brand .te-brand-content:hover .te-brand-content-intro {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
  .te-inline-brand .te-brand-content figcaption {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    width: 50%;
    padding: 20px 0 20px 20px;
  }
  .te-inline-brand .te-brand-content-intro {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  .te-inline-brand .te-brand-content-logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 479px) {
  .te-inline-brand .te-collection-product {
    width: 50%;
  }
  .te-inline-brand .te-collection-img {
    height: 200px;
  }
}
