.te-modular-dropdown {
  width: 100%;
  max-width: 260px;
}
.te-modular-dropdown.te-tablet,
.te-modular-dropdown.te-desktop {
  width: 50%;
}
.te-modular-dropdown-select {
  border: 1px solid #e7e7e7;
  text-align-last: left;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.8em;
  position: relative;
}
.te-modular-dropdown-select:hover {
  cursor: pointer;
}
.te-modular-dropdown-form-select {
  color: #4f5058;
  padding: 13px 10px;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background: transparent;
}
.te-modular-dropdown-form-select:active,
.te-modular-dropdown-form-select:hover,
.te-modular-dropdown-form-select:focus {
  outline: 0;
}
.te-modular-dropdown-arrow_icon {
  position: absolute;
  width: 1.8em;
  pointer-events: none;
  right: 5px;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
  transition: all 0.3s ease;
}
.te-modular-dropdown-arrow_icon.te-rotate {
  transform: translateY(-50%) translateX(-50%) rotate(0);
}
.te-modular-dropdown-title {
  position: relative;
  display: flex;
  border: 1px solid #e7e7e7;
  padding: 15px 20px 15px 15px;
  color: #4f5058;
  max-width: 260px;
}
.te-modular-dropdown-title:focus {
  outline: none;
}
.te-modular-dropdown-menu-wrapper {
  outline: none;
  position: absolute;
  background-color: white;
  z-index: 999999;
  width: 100%;
  max-width: 260px;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  box-sizing: border-box;
}
.te-modular-dropdown-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.te-modular-dropdown-menu-item.te-disabled {
  user-select: none;
  pointer-events: none;
  color: #e7e7e7;
}
.te-modular-dropdown-menu-item-wrapper {
  padding: 10px 15px;
  color: inherit;
}
.te-modular-dropdown-menu-item-wrapper:hover {
  background-color: #efefef;
  cursor: pointer;
}
.te-modular-dropdown-menu-item-inner {
  display: flex;
  justify-content: space-between;
}
.te-modular-dropdown-menu-item {
  outline: none;
}
