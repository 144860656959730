.te-form,
.te-styled .te-form {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.te-form-input-group,
.te-styled .te-form-input-group {
  display: flex;
  justify-content: space-between;
}
.te-form-input-group div,
.te-styled .te-form-input-group div {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.te-form-error,
.te-styled .te-form-error {
  color: #cd5550;
  font-size: 0.9em;
  padding-left: 10px;
  pointer-events: none;
}
.te-form input,
.te-styled .te-form input {
  border: 1px solid #a6aaa9;
  flex: 1;
  font-size: inherit;
  padding: 10px 20px;
  margin: 10px;
}
.te-checkout-form-container.open,
.te-styled .te-checkout-form-container.open {
  min-height: 150px !important;
}
