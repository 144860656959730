.te-menu {
  list-style: none;
  padding-left: 0;
}
.te-menu .te-menu-item {
  padding-left: 35px;
  text-transform: uppercase;
  color: white;
}
@media (min-width: 768px) {
  .te-menu .te-menu-item link:hover {
    cursor: pointer;
  }
}
.te-link {
  display: block;
  outline: 0;
  border: none;
  background: transparent;
  text-align: left;
  width: 100%;
}
.te-back-button {
  cursor: pointer;
  color: #333;
}
