body.no-scroll {
  overflow: hidden;
}
body.no-scroll ::-webkit-scrollbar {
  display: none;
}
.te-dialog,
.te-styled .te-dialog {
  scroll-behavior: smooth;
  background: #b4b4b4;
  background-color: rgba(180, 180, 180, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9000000;
  overflow-y: scroll;
  min-width: 300px;
  padding: 10px;
  max-width: 100vw;
}
.te-dialog-card,
.te-styled .te-dialog-card {
  -webkit-box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  align-items: stretch;
  background: white;
  box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: 1350px;
  overflow: scroll;
  position: relative;
  z-index: 800000;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-backface-visibility: hidden;
}
.te-dialog-card.display-block,
.te-styled .te-dialog-card.display-block {
  display: block;
}
.te-dialog-card.display-none,
.te-styled .te-dialog-card.display-none {
  display: none;
}
.te-dialog-inner,
.te-styled .te-dialog-inner {
  align-items: stretch;
  box-sizing: border-box;
  display: block;
  flex: 1;
  height: auto;
  padding-top: 50px;
}
.te-dialog-inner-product,
.te-styled .te-dialog-inner-product {
  margin: 0;
  padding: 0;
}
.te-dialog-inner-product.te-xlarge,
.te-styled .te-dialog-inner-product.te-xlarge {
  margin-top: 10px;
  height: 600px;
  max-height: 600px;
}
.te-dialog-inner-product.te-large,
.te-styled .te-dialog-inner-product.te-large {
  margin-top: 10px;
  height: 550px;
  max-height: 550px;
}
.te-dialog-header-fixed,
.te-styled .te-dialog-header-fixed {
  background-color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  width: 94%;
  max-width: 1350px;
  padding: 10px 0 10px 0;
  height: 50px;
  top: 0;
  border-bottom: none;
  position: absolute;
  box-sizing: border-box;
}
.te-dialog-header-fixed.te-mobile,
.te-styled .te-dialog-header-fixed.te-mobile {
  border-bottom: 1px solid #e7e7e7;
}
.te-dialog-header-fixed.on-modal-scroll,
.te-styled .te-dialog-header-fixed.on-modal-scroll {
  top: 10px;
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
}
.te-dialog-header-fixed.on-modal-scroll::after,
.te-styled .te-dialog-header-fixed.on-modal-scroll::after {
  content: '';
  top: -10px;
  left: 0;
  width: 102%;
  height: 10px;
  background-color: #d9d9d9;
  z-index: 9999999;
  position: absolute;
}
.te-dialog-header-fixed-logo,
.te-styled .te-dialog-header-fixed-logo {
  position: relative;
  margin-right: auto;
}
.te-dialog-header-fixed-logo-wrapper,
.te-styled .te-dialog-header-fixed-logo-wrapper {
  margin-left: 20px;
}
.te-dialog-header-fixed-logo-img,
.te-styled .te-dialog-header-fixed-logo-img {
  max-width: 250px;
  max-height: 25px;
}
.te-dialog-header-fixed-cross,
.te-styled .te-dialog-header-fixed-cross {
  width: 20px;
  position: relative;
  margin-right: 10px;
  top: unset;
  right: unset;
  color: #4f5058;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart {
  width: 30px;
  height: 30px;
  margin-right: 13px;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart svg,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart svg {
  height: 24px;
  margin-top: 4px;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart span,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart span {
  left: 0;
  bottom: -2px;
}
@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
.te-product-tile {
  max-width: 200px;
  min-width: 160px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: inherit;
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: 30px auto;
}
@media (min-width: 480px) {
  .te-product-tile {
    max-width: 160px;
    min-width: 200px;
  }
}
.te-product-tile:hover {
  color: inherit;
}
.te-product-tile .te-product-tile-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.3s ease;
}
.te-product-tile .te-product-tile-img-wrapper {
  height: 240px;
  position: relative;
}
.te-product-tile .te-product-tile-img-invalid {
  background-color: #dddddd;
  width: 75%;
  max-width: 225px;
  margin: 0 auto;
  height: 100%;
}
.te-product-tile .te-product-tile-img:hover {
  opacity: 0.9;
}
.te-product-tile-details {
  padding: 5px 0 10px;
  text-align: center;
}
.te-product-tile-price {
  display: flex;
  font-size: 18px;
  justify-content: center;
  padding-bottom: 10px;
}
.te-product-tile .te-details-brand {
  color: #2c2c2c;
  font-size: 0.88em;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.te-product-tile .te-details-name {
  color: #949494;
  font-size: 0.82em;
  height: 2.6em;
  line-height: 1.3em;
  margin: 5px 0 10px;
  padding: 0;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 2) {
  .te-product-tile .te-details-name {
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.te-product-tile .te-basic-price-main {
  font-weight: 500;
}
.te-product-tile .te-button {
  line-height: 40px;
  min-width: 120px;
}
.te-product-tile.te-small {
  max-width: 160px;
}
.te-product-tile.te-small .te-product-tile-img-wrapper {
  height: 240px;
}
.te-product-tile.te-medium {
  max-width: 240px;
  min-width: 160px;
}
.te-product-tile.te-medium .te-product-tile-img-wrapper {
  height: 300px;
}
.te-product-tile.te-large {
  max-width: 240px;
  min-width: 300px;
}
.te-product-tile.te-large .te-product-tile-img-wrapper {
  height: 360px;
}
.te-product-tile.te-horizontal > div {
  align-items: stretch;
  display: flex;
}
.te-product-tile.te-horizontal .te-product-tile-details {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  width: 50%;
}
.te-product-tile.te-horizontal .te-basic-price-main {
  flex-wrap: wrap;
  justify-content: center;
}
.te-product-tile.te-horizontal .te-product-tile-img-wrapper {
  width: 50%;
}
.te-product-tile.te-horizontal.te-small {
  max-width: 320px;
}
.te-product-tile.te-horizontal.te-medium {
  max-width: 470px;
}
.te-product-tile.te-horizontal.te-large {
  max-width: 500px;
}
.te-product-tile .te-preowned-label {
  position: absolute;
  bottom: 50px;
  left: 0;
}
